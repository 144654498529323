import React, { useEffect, useState } from "react";
import Header from "../Navbar/Navbar";
import { useLocation } from "react-router-dom";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import "./WellnessDetail.css";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Accordion from "react-bootstrap/Accordion";
const WellNessDetail = () => {
  const { state } = useLocation();
  const { drip } = state;
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.scrollTo(0, 0);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  console.log(drip, "drip");
  return (
    <>
      <Header />
      <div className="container d-flex flex-column gap-2 my-3 wellnessContainer">
        <div className="container">
          <div className="row mt-2 gy-5">
            <div style={{ marginTop: "10%" }}>
              <Grid container>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <div className="col-md-4" key={drip.id}>
                    <div className="d-flex flex-column gap-3 grid-item-div">
                      <img src={drip.image} className="grid-img" />
                    </div>
                  </div>
                  {/* <Card
                sx={{
                  maxWidth: 400,
                  padding: "10%",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  borderRadius: "15px",
                }}
                variant="outlined"
              >
                <CardContent sx={{ display: "flex" }}>
                  <img src={drip.image} className="grid-img" />
                </CardContent>
              </Card> */}
                </Grid>
                <Grid item lg={8} md={6} sm={6} xs={12}>
                  <Typography
                    variant="h4"
                    sx={{
                      marginTop: { lg: "0%", md: "0%", sm: "0%", xs: "5%" },
                      marginBottom: { lg: "0%", md: "0%", sm: "0%", xs: "3%" },
                    }}
                  >
                    {drip.title}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "18px", color: "#555555", mb: 2 }}
                  >
                    {drip.desc}
                  </Typography>
                  {isDesktop ? (
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Benefits:</Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li>
                              <span>{drip.b1}</span>
                            </li>
                            {drip.b2 && (
                              <li>
                                <span>{drip.b2}</span>
                              </li>
                            )}
                            {drip.b3 && (
                              <li>
                                <span>{drip.b3}</span>
                              </li>
                            )}
                            {drip.b4 && (
                              <li>
                                <span>{drip.b4}</span>
                              </li>
                            )}
                            {drip.b5 && (
                              <li>
                                <span>{drip.b5}</span>
                              </li>
                            )}
                            {drip.b6 && (
                              <li>
                                <span>{drip.b6}</span>
                              </li>
                            )}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Ingredients:</Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li>
                              <span>{drip.i1}</span>
                            </li>
                            {drip.i2 && (
                              <li>
                                <span>{drip.i2}</span>
                              </li>
                            )}
                            {drip.i3 && (
                              <li>
                                <span>{drip.i3}</span>
                              </li>
                            )}
                            {drip.i4 && (
                              <li>
                                <span>{drip.i4}</span>
                              </li>
                            )}
                            {drip.i5 && (
                              <li>
                                <span>{drip.i5}</span>
                              </li>
                            )}
                            {drip.i6 && (
                              <li>
                                <span>{drip.i6}</span>
                              </li>
                            )}
                            {drip.i7 && (
                              <li>
                                <span>{drip.i7}</span>
                              </li>
                            )}
                            {drip.i8 && (
                              <li>
                                <span>{drip.i8}</span>
                              </li>
                            )}
                            {drip.i9 && (
                              <li>
                                <span>{drip.i9}</span>
                              </li>
                            )}
                            {drip.i10 && (
                              <li>
                                <span>{drip.i10}</span>
                              </li>
                            )}
                            {drip.i11 && (
                              <li>
                                <span>{drip.i11}</span>
                              </li>
                            )}
                            {drip.i12 && (
                              <li>
                                <span>{drip.i12}</span>
                              </li>
                            )}
                            {drip.i13 && (
                              <li>
                                <span>{drip.i13}</span>
                              </li>
                            )}
                            {drip.i14 && (
                              <li>
                                <span>{drip.i14}</span>
                              </li>
                            )}
                            {drip.i15 && (
                              <li>
                                <span>{drip.i15}</span>
                              </li>
                            )}
                            {drip.i16 && (
                              <li>
                                <span>{drip.i16}</span>
                              </li>
                            )}
                            {drip.i17 && (
                              <li>
                                <span>{drip.i17}</span>
                              </li>
                            )}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ) : (
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Benefits:</Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li>
                              <span>{drip.b1}</span>
                            </li>
                            {drip.b2 && (
                              <li>
                                <span>{drip.b2}</span>
                              </li>
                            )}
                            {drip.b3 && (
                              <li>
                                <span>{drip.b3}</span>
                              </li>
                            )}
                            {drip.b4 && (
                              <li>
                                <span>{drip.b4}</span>
                              </li>
                            )}
                            {drip.b5 && (
                              <li>
                                <span>{drip.b5}</span>
                              </li>
                            )}
                            {drip.b6 && (
                              <li>
                                <span>{drip.b6}</span>
                              </li>
                            )}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Ingredients:</Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li>
                              <span>{drip.i1}</span>
                            </li>
                            {drip.i2 && (
                              <li>
                                <span>{drip.i2}</span>
                              </li>
                            )}
                            {drip.i3 && (
                              <li>
                                <span>{drip.i3}</span>
                              </li>
                            )}
                            {drip.i4 && (
                              <li>
                                <span>{drip.i4}</span>
                              </li>
                            )}
                            {drip.i5 && (
                              <li>
                                <span>{drip.i5}</span>
                              </li>
                            )}
                            {drip.i6 && (
                              <li>
                                <span>{drip.i6}</span>
                              </li>
                            )}
                            {drip.i7 && (
                              <li>
                                <span>{drip.i7}</span>
                              </li>
                            )}
                            {drip.i8 && (
                              <li>
                                <span>{drip.i8}</span>
                              </li>
                            )}
                            {drip.i9 && (
                              <li>
                                <span>{drip.i9}</span>
                              </li>
                            )}
                            {drip.i10 && (
                              <li>
                                <span>{drip.i10}</span>
                              </li>
                            )}
                            {drip.i11 && (
                              <li>
                                <span>{drip.i11}</span>
                              </li>
                            )}
                            {drip.i12 && (
                              <li>
                                <span>{drip.i12}</span>
                              </li>
                            )}
                            {drip.i13 && (
                              <li>
                                <span>{drip.i13}</span>
                              </li>
                            )}
                            {drip.i14 && (
                              <li>
                                <span>{drip.i14}</span>
                              </li>
                            )}
                            {drip.i15 && (
                              <li>
                                <span>{drip.i15}</span>
                              </li>
                            )}
                            {drip.i16 && (
                              <li>
                                <span>{drip.i16}</span>
                              </li>
                            )}
                            {drip.i17 && (
                              <li>
                                <span>{drip.i17}</span>
                              </li>
                            )}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <Contact />
      <Footer />
    </>
  );
};

export default WellNessDetail;
