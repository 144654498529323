import React, { useEffect, useState } from "react";
import "../Servicess/services.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate, useLocation } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import ValidateTextField from "../ValidateTextField/ValidateTextField";
import file4 from "../assets/files/Form 4 (IV Infusions Order Form).pdf-.pdf";

AOS.init();
const Services6 = () => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 ? true : false
  );
  const updateDimensions = () => {
    setIsMobile(window.innerWidth < 768 ? true : false);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isMobile]);
  let navigate = useNavigate();
  const location = useLocation();
  const navigate_ = (url) => {
    navigate(url);
  };
  const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const url = location.pathname;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  return (
    <>
      <div
        className={` ${
          url === "/home"
            ? isMobile
              ? "services6-mobile"
              : "services6_main"
            : isMobile
            ? "section6bannermobilebg"
            : "services6bannerbg"
        }`}
      >
        <div
          className="services_data"
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-duration="1000"
          data-aos-easing="ease-in-sine"
        >
          <p
            className={` ${
              url === "/home"
                ? "services_heading1"
                : "services_heading-wellness"
            }`}
            onClick={() => navigate_("/IVInfusions")}
          >
            IV Infusions{" "}
          </p>
          <p
            className={` ${
              url === "/home" ? "services_para1" : "services_para-wellness"
            }`}
          >
            Our list of IV infusions is robust and keeps on expanding to help
            treat patients and their conditions.
          </p>

          {/* {url === "/home" ? */}
          <button
            onClick={() => navigate_("/IVInfusions")}
            className={` ${
              url === "/home" ? "services_btn" : "services-btn-wellness"
            }`}
          >
            {url === "/home" ? "Learn More " : "Book a Consultation"}
          </button>

          {/* <a href={file4} download="IVInfusions_treatment_Form" target='_blank'>
                            <button className={"services-btn-wellness"}>Book a Consultation</button>
                        </a>} */}
        </div>
      </div>
      {open && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <div className="row">
                <div className="col-12">
                  <ValidateTextField
                    label="Name*"
                    name="patient_name"
                    // value={values.patient_name} onChange={handleFields}
                  />
                </div>
                <div className="col-12">
                  <ValidateTextField
                    label="Phone Number*"
                    // onBlur={validatePhone} name="patient_phone_no" value={values.patient_phone_no} onChange={handleFields}
                  />
                  {/* <div className="text-danger" style={{ marginTop: '-15px' }}>{errors.phone}</div> */}
                </div>
                <div className="col-12">
                  <ValidateTextField
                    label="Email*"
                    // onBlur={validateEmail} name="patient_email" value={values.patient_email} onChange={handleFields}
                  />
                  {/* <div className="text-danger" style={{ marginTop: '-15px' }}>{errors.email}</div> */}
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      )}
    </>
  );
};

export default Services6;
