import React, { useEffect, useState } from "react";
import { Carousel, Container, Row, Col } from "react-bootstrap";
// import doctor from '.././assets/images/doctor.png'
// import patient from ".././assets/images/paatient.png";
import StarRatings from "react-star-ratings";
// const fakeData = [
//     {
//         plusId: 1,
//         reviewAuthorImage: doctor,
//         reviewText: "Patient, sweet, informative and helpful staff, and clean facility",
//         reviewRating: 5,

//     },
//     {
//         plusId: 2,
//         reviewAuthorImage: patient,
//         reviewText: "The best!",
//         reviewRating: 4,

//     },
//     {
//         plusId: 3,
//         reviewAuthorImage: doctor,
//         reviewText: "Only rating provided.",
//         reviewRating: 5,

//     },
//     {
//         plusId: 4,
//         reviewAuthorImage: patient,
//         reviewText: "Good Services",
//         reviewRating: 4,

//     },
//     {
//         plusId: 5,
//         reviewAuthorImage: doctor,
//         reviewText: "Only rating provided.",
//         reviewRating: 4,

//     },
// ]
const Testimonial = ({ reviewData }) => {
  // console.log("reviewData________--", reviewData)
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 ? true : false
  );
  const updateDimensions = () => {
    setIsMobile(window.innerWidth < 768 ? true : false);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isMobile]);
  // let temp = reviewData.length > 0 ? [...reviewData] : [...fakeData]
  console.log(reviewData);
  return (
    <div className="py-5 testimonial">
      <h1 className="testi_heading text-center mb-2"> Testimonials </h1>
      <Carousel>
        {reviewData.map((review) => {
          return (
            <Carousel.Item key={review.plusId}>
              <Container>
                <Row>
                  {isMobile ? (
                    <Col md={10} style={{ height: "80vh" }}>
                      <div className="testi_div">
                        <div className="testimonial_image_div me-3">
                          <img
                            src={review.profile_photo_url}
                            alt="Profile Pic"
                            className="image-fluid  testimonial_image"
                          />
                        </div>
                        <div className="testimonial_para">
                          <p>
                            <q>{review.reviewText}</q>
                          </p>
                        </div>
                        <div className="name_job">
                          <div>
                            <div>
                              <h2 className="testimonial_name">
                                {" "}
                                {review.author_name}
                              </h2>
                              {/* <p className='testimonial_text'>Doctor</p> */}
                              {/* <StarRatings
                                rating={parseInt(review.reviewRating)}
                                starRatedColor="#25ABDF"
                                // changeRating={this.changeRating}
                                numberOfStars={5}
                                name="rating"
                                starDimension="30px"
                              /> */}
                              <StarRatings
                                rating={review.rating}
                                starRatedColor="orange"
                                numberOfStars={5}
                                name="rating"
                                starDimension="20px"
                                starSpacing="2px"
                              />
                              <p style={{ color: "#000" }}>{review.text}</p>
                            </div>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </Col>
                  ) : (
                    <>
                      <Col md={10} className="new" style={{ height: "40vh" }}>
                        <div className="testi_div">
                          <div className="testimonial_image_div me-3">
                            <img
                              src={review.profile_photo_url}
                              alt="Profile Pic"
                              className="image-fluid testimonial_image"
                            />
                          </div>
                          <div className="testimonial_para">
                            <p>
                              <q>{review.reviewText}</q>
                            </p>
                          </div>
                          <div className="name_job">
                            <div>
                              <div>
                                <h2 className="testimonial_name">
                                  {review.author_name}
                                </h2>
                                {/* <p className='testimonial_text'>Designation</p> */}
                                {/* <StarRatings
                                  rating={parseInt(review.reviewRating)}
                                  starRatedColor="#25ABDF"
                                  // changeRating={this.changeRating}
                                  numberOfStars={5}
                                  name="rating"
                                  starDimension="30px"
                                /> */}
                                <StarRatings
                                  rating={review.rating}
                                  starRatedColor="orange"
                                  numberOfStars={5}
                                  name="rating"
                                  starDimension="20px"
                                  starSpacing="2px"
                                />
                                <p style={{ color: "#000" }}>{review.text}</p>
                              </div>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              </Container>
            </Carousel.Item>
          );
        })}

        {/* <Carousel.Item>
                    <Container>
                        <Row>
                            {isMobile ? (<Col md={10} className="new">
                                <div className='testi_div'>
                                    <div className='testimonial_image_div me-3 '>
                                        <img src={patient} alt='Profile Pic' className='image-fluid  testimonial_image' />
                                    </div>
                                    <div className='testimonial_para'>
                                        <p ><q>Executive Infusion has changed my monthly infusions a dreaded experience into an experience that is safe and pleasant. I can't imagine getting my infusions anywhere else!</q></p>
                                    </div>
                                    <div className='name_job'>

                                        <div>
                                            <div>
                                                <h2 className='testimonial_name'>Monica</h2>
                                                <p className='testimonial_text'>Patient</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </Col>) :
                                (<>
                                    <Col md={10} className="new">
                                        <div className='testi_div'>
                                            <div className='testimonial_image_div me-3'>
                                                <img src={doctor} alt='Profile Pic' className='image-fluid  testimonial_image' />
                                            </div>
                                            <div className='testimonial_para'>
                                                <p ><q>As a doctor, I have referred many of my patients requiring infusion care to Executive Infusion. Always found them helpful in routine plus difficult cases too.</q></p>
                                            </div>
                                            <div className='name_job'>

                                                <div>
                                                    <div>
                                                        <h2 className='testimonial_name'> Lewis</h2>
                                                        <p className='testimonial_text'>Doctor</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                    </Col>


                                </>)
                            }
                        </Row>
                    </Container>
                </Carousel.Item>
                <Carousel.Item>
                    <Container>
                        <Row>
                            {isMobile ? (<Col md={10}>
                                <div className='testi_div'>
                                    <div className='testimonial_image_div me-3'>
                                        <img src={patient} alt='Profile Pic' className='image-fluid testimonial_image' />
                                    </div>
                                    <div className='testimonial_para'>
                                        <p ><q>The team at Executive Infusion coordinated with us and delivered all the wellness drugs timely. Highly recommend them to everyone who needs infusion care – James, Pharmacy Owner .</q></p>
                                    </div>
                                    <div className='name_job'>

                                        <div>
                                            <div>
                                                <h2 className='testimonial_name'>James</h2>
                                                <p className='testimonial_text'>Designation</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </Col>) :
                                (<>

                                    <Col md={10} className="new">
                                        <div className='testi_div'>
                                            <div className='testimonial_image_div me-3 '>
                                                <img src={doctor} alt='Profile Pic' className='image-fluid  testimonial_image' />
                                            </div>
                                            <div className='testimonial_para'>
                                                <p ><q>Executive Infusion has changed my monthly infusions a dreaded experience into an experience that is safe and pleasant. I can't imagine getting my infusions anywhere else!</q></p>
                                            </div>
                                            <div className='name_job'>

                                                <div>
                                                    <div>
                                                        <h2 className='testimonial_name'>Monica</h2>
                                                        <p className='testimonial_text'>Patient</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                    </Col>

                                </>)
                            }
                        </Row>
                    </Container>
                </Carousel.Item> */}
      </Carousel>
    </div>
  );
};

export default Testimonial;
