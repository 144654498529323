import React from 'react'
import "./Privacy.css"
const Privacy = () => {
    return (
        <>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className='container privacy'>
                <div className='row privacy-div'>
                    <h1 className='mt-2 privacy-title'>Privacy Policy</h1>
                    <hr className='my-5 hrColor' />
                    <p className='privacy-p'>The policy describes how Executive Infusion may collect user information through interactions on the website. By using this website, you as a user agree to the terms in this Privacy Policy. We reserve the right to update this Privacy Policy and your use of the website will mean that you accept the revisions in Privacy Policy. </p>
                    <hr className='my-3' />
                    <div>
                        <h3 className='my-3 privacy-h3'>Personal Information Collection</h3>
                        <p className='privacy-p'>We want the users of the website to understand how the personal information they provide on the website is collected and used. Personal information helps in identifying, locating, and contacting a user. We collect and store this information of the users and use it to:</p>
                        <ul>
                            <li className='my-3'>Share information about our services and products through notifications, newsletters, and email alerts. </li>
                            <li className='my-3'>Track general location information of the user from their device or browser, which is later used for internal purposes.  </li>
                            <li className='my-3'>If you submit any personal information related to a third person on the website, you represent having the authority to do so and permit the use of that information according to this Privacy Policy.</li>
                        </ul>
                    </div>
                    <hr className='my-3' />
                    <h3 className='my-3 privacy-h3'>Disclosure & Use Of Personal Information</h3>
                    <p className='privacy-p'>Executive Infusion uses personal information for responding to user requests – such as booking an appointment, providing patient support, and more. Personal information is also used for sending administrative information and marketing purposes. </p>
                    <p className='privacy-p'>We may also use personal information for user experience personalization when they are interacting with the website, including services tailored to them. In case Executive Infusion’s assets are transferred or sold, the personal information may also be shared with or transferred to the third party as a part of the transaction. </p>
                    <p className='privacy-p'>To an extent that is permitted by the law, we may also share the personal information with our affiliated partners, who can use it for communication and marketing. Moreover, personal information may also be used for investigating security breaches or otherwise cooperating with authorities under a legal matter. </p>

                    <hr className='my-3' />
                </div>
            </div>
        </>
    )
}

export default Privacy
