import React from 'react'
import "./WellNessDivs.css"
const WellNessDivs = ({ welltitle1, welltitle2, wellsubtitle, wellimage }) => {
    return (
        <div className='container position-relative d-flex justify-content-center wrapper-wellness'>
            <div className='grey-div'>
                <img src={wellimage} alt="image" className='divs-image' />
            </div>
            <div className='blue-div'>
                <p className='blue-div-title'>{welltitle1} <br /> {welltitle2}</p>
                <p className='white-line'></p>
                <span className='blue-div-subtitle'>{wellsubtitle} </span>
            </div>
        </div>
    )
}

export default WellNessDivs
