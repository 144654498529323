import React from 'react'
import Header from '../Componenets/Navbar/Navbar'
import Footer from '../Componenets/Footer/Footer'
import SubmitRef from '../Componenets/SubmitReferal/SubmitRef'
import Contact from '../Componenets/Contact/Contact'

const SubmitReferal = () => {
    return (
        <>
            <Header />
            <SubmitRef />
            <Contact />
            <Footer />
        </>

    )
}

export default SubmitReferal