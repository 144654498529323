import React from "react";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Header from "../Navbar/Navbar";
import Testimonial from "../Testimonial/Testimonial";

const ServicesSublayout = (props) => {
  return (
    <>
      <Header />
      {props.children}
      {/* {props.reviewData.length > 0 && <Testimonial reviewData={props.reviewData} />} */}

      <Contact />
      <Footer />
    </>
  );
};

export default ServicesSublayout;
