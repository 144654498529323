import axios from "./axios";

export const addDataApi = (table, payload) => {
  return axios.post("/api/write/" + table, payload);
};
// export const fetchReviews = () => {
//   return axios.get(
//     "https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJ0fnXAhw1O4gRLORDSGrbb8k&fields=reviews&key=AIzaSyAL9D1zdnszzf7FLAxnNr8vmyfJHUjdtrI"
//   );
// };

// CORS ERROR
export const fetchReviews = async () => {
  try {
    const response = await axios.get(
      "https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJ0fnXAhw1O4gRLORDSGrbb8k&fields=reviews&key=AIzaSyAL9D1zdnszzf7FLAxnNr8vmyfJHUjdtrI"
    );
    return response;
  } catch (error) {
    if (error.response) {
      console.log("Server responded with an error:", error.response);
    } else if (error.request) {
      console.log("Network error:", error.request);
    } else {
      console.log("Error:", error.message);
    }
  }
};
