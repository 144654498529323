export const SpecialDrugData = [
    {
        title: "Clinical Drug Management",
        subtitle: "Ensuring far more than just dispensing specialty drugs is our focus. We manage and use the drugs according to disease protocol. "
    },
    {
        title: "High-Quality Guaranteed ",
        subtitle: "High-quality specialty drugs are acquired from reputable sources to treat patients and safety protocols are followed when using them."
    },
    {
        title: "Tailored Patient Care",
        subtitle: "Patients suffering from complex conditions receive infusion care through specialty drugs. We administer all such drugs under clinical monitoring."
    }
]