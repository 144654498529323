import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WhiteSimpleTick from "../assets/svgs/WhiteSimpleTick";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import ValidateTextField from "../ValidateTextField/ValidateTextField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Service = ({
  services_title1,
  services_title2,
  services_subtitle,
  services_btn_text,
  servicesBgClass,
  ambulatoryMobileBgClass,
  file,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 ? true : false
  );
  const updateDimensions = () => {
    setIsMobile(window.innerWidth < 768 ? true : false);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isMobile]);

  const location = useLocation();

  const url = location.pathname;
  let navigate = useNavigate();
  const navigate_ = (url) => {
    navigate(url);
  };
  return (
    <>
      <div
        className={
          isMobile ? `${ambulatoryMobileBgClass}` : `${servicesBgClass}`
        }
      >
        <div
          className="services_data_services  container"
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-duration="1000"
          data-aos-easing="ease-in-sine"
        >
          {url === "/homeInfusion" ? (
            <div className="special-div mb-3">
              <WhiteSimpleTick className="" />
              <p className="special-text">
                Treatment within the comfort of home.
              </p>
            </div>
          ) : (
            ""
          )}
          <p className="services_headingClass">
            {services_title1} <br /> {services_title2}
          </p>
          <p className="services_para1">{services_subtitle}</p>

          <button
            className="servicesBtnClass"
            // onClick={() => handleOpen()}
            onClick={() => navigate_("/appointment")}
          >
            {services_btn_text}
          </button>
        </div>
      </div>
      {open && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <div className="row">
                <div className="col-12">
                  <ValidateTextField
                    label="Name*"
                    name="patient_name"
                    // value={values.patient_name} onChange={handleFields}
                  />
                </div>
                <div className="col-12">
                  <ValidateTextField
                    label="Phone Number*"
                    // onBlur={validatePhone} name="patient_phone_no" value={values.patient_phone_no} onChange={handleFields}
                  />
                  {/* <div className="text-danger" style={{ marginTop: '-15px' }}>{errors.phone}</div> */}
                </div>
                <div className="col-12">
                  <ValidateTextField
                    label="Email*"
                    // onBlur={validateEmail} name="patient_email" value={values.patient_email} onChange={handleFields}
                  />
                  {/* <div className="text-danger" style={{ marginTop: '-15px' }}>{errors.email}</div> */}
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      )}
    </>
  );
};

export default Service;
