import React from "react";
import "../Servicess/services.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

AOS.init();
const Services4 = () => {
  let navigate = useNavigate();
  const navigate_ = (url) => {
    navigate(url);
  };
  return (
    <>
      <div className="services4_main">
        <div
          className="services_data"
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-duration="1000"
          data-aos-easing="ease-in-sine"
        >
          <p
            className="services_heading1"
            onClick={() => navigate_("/services/compounding")}
          >
            <span className="highlightedText">Compounding</span>
          </p>
          <p className="services_para1">
            Customized Meds for Personalized Healing.
          </p>
          <button
            className="services_btn1"
            onClick={() => navigate_("/services/compounding")}
          >
            Learn More{" "}
          </button>
        </div>
      </div>
    </>
  );
};

export default Services4;
