import React from 'react'
import '../PayBill/pabill.css'
import Paybill1 from '../assets/images/paybill1.png'
import Paybill2 from '../assets/images/paybill2.png'
import { Row, Col } from 'react-bootstrap'

const Paybill = () => {
    return (
        <>
            <div className='paybill_main'>
                <div className='paybill_data'>
                    <div className='paybill_heading'>
                        <p>Pay My Bill</p>
                    </div>
                    <div >
                        <Row className='paybill_images_div'>
                            <Col className='paubill_col'><img src={Paybill1} className='paybill_images' alt="paybill1" /></Col>
                            <Col className='paubill_col'><img src={Paybill2} className='paybill_images' alt="paybill2" /></Col>
                        </Row>

                    </div>


                </div>

            </div>
        </>
    )
}

export default Paybill