import React from 'react'

const BlackArrowDown = () => {
    return (
        <>
            <svg
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined svgStyleDropDown
            "
                width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.25 7.875L10.5 13.125L15.75 7.875"
                    // stroke="#3A3A3A" 
                    strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        </>
    )
}

export default BlackArrowDown
