import React from "react";
import "../Servicess/services.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate, useLocation } from "react-router-dom";

AOS.init();
const Services1 = () => {
  const location = useLocation();
  const url = location.pathname;
  let navigate = useNavigate();
  const navigate_ = (url) => {
    navigate(url);
  };
  return (
    <>
      <div
        className="services1_main"
        // className={` ${
        //   url === "/home" ? "services1_main" : "services1-wellness"
        // }`}
      >
        <div
          className="services_data"
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-duration="1000"
          data-aos-easing="ease-in-sine"
        >
          <p
            onClick={() => navigate_("/services/ambulatory")}
            className="services_heading1"
            // className={` ${
            //   url === "/home"
            //     ? "services_heading1"
            //     : "services1-heading-wellness"
            // }`}
          >
            {/* {url === "/home" ? ( */}
            <>
              <span className="highlightedText">AIC</span>
              <span
                style={{
                  textTransform: "lowercase",
                }}
              >
                <span className="highlightedText">
                  (ambulatory infusion center)
                </span>
              </span>{" "}
            </>
            {/* ) : (
              <span style={{ backgroundColor: "#fffff7" }}>
                {" "}
                Ambulatory <br />
                Infusion Center
              </span>
            )} */}
          </p>
          <p
            className="services_para1"
            // className={` ${
            //   url === "/home" ? "services_para1" : "services1-para-wellness"
            // }`}
          >
            Our clinical pharmacists and registered nurses manage ambulatory
            infusions to support the therapy needs of patients.
          </p>
          <button
            onClick={() => navigate_("/services/ambulatory")}
            className="services_btn"
            // className={` ${
            //   url === "/home" ? "services_btn" : "services1-btn-wellness"
            // }`}
          >
            Learn More
            {/* {url === "/home" ? <>Learn More</> : <> Book An Appointment</>} */}
          </button>
        </div>
      </div>
    </>
  );
};

export default Services1;
