import React, { useEffect } from 'react'
import Footer from '../Componenets/Footer/Footer'
import Header from '../Componenets/Navbar/Navbar'
import Referal from '../Componenets/Raferal/Raferal'

const Raferal = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <Header />
            <Referal />
            <Footer />
        </>
    )
}

export default Raferal
