import React from "react";
import { useNavigate } from "react-router-dom";

const NavigationPage = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate("/submit-a-referral");
  }, []);

  return <div>NavigationPage</div>;
};

export default NavigationPage;
