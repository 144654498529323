import React from 'react'

const GreyTickSvg = () => {
    return (
        <>
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle opacity="0.6" cx="9.5" cy="9.5" r="9.5" fill="white" />
                <g opacity="0.9">
                    <path d="M13.6663 6.5L7.24967 12.9167L4.33301 10" stroke="white" strokeWidth="1.4" strokeLinecap="round"
                        strokeLinejoin="round" />
                </g>
            </svg>

        </>
    )
}

export default GreyTickSvg
