import React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import facebok from "../assets/images/Facebook.png";
import linkedin from "../assets/images/linkdin.png";
import google from "../assets/images/Google.png";
import email from "../assets/images/email.png";
import map from "../assets/images/map-pin.png";
import mail from "../assets/images/temp.png";
import phone from "../assets/images/phone.png";
import print from "../assets/images/FAX.png";
import footer1 from "../assets/images/lastfooter1.png";
import footer2 from "../assets/images/lastfooter2.png";
import footer3 from "../assets/images/lastfooter3.png";
import Map from "./Map";
import "./Footer.css";

AOS.init();

const Footer = () => {
  let navigate = useNavigate();
  const routeChange = () => {
    window.open("https://www.xcentricservices.com/");
  };
  // const mailtoHref =
  //   "https://mail.google.com/mail/?view=cm&fs=1&to=info@executiveinfusion.com";
  const mailtoHref = "mailto:info@executiveinfusion.com";

  const openMap = () => {
    window.open(
      "https://maps.google.com?q=17000+Executive+Plaza+Dr+Ste+201,+Dearborn,+MI+48126"
    );
  };

  const location = {
    address: "17000 Executive Plaza Dr Ste 201, Dearborn, MI 48126",
    lat: 42.32569,
    lng: -83.21252,
  };

  return (
    <div className="footer">
      <Container>
        <Row className="py-5">
          <Col md={3} style={{ textAlign: "center" }}>
            <div className="executive_destop">
              <div style={{ textAlign: "left" }}>
                <h3
                  className="footer_link_heading"
                  style={{
                    fontSize: "24px",
                  }}
                  onClick={() => navigate("/home")}
                >
                  Executive Infusion
                </h3>
                <div className="mb-1">
                  <NavLink to="/home" className="footer_link">
                    Home
                  </NavLink>
                </div>
                {/* <div className='mb-1'>
                  <NavLink to="/faq" className="footer_link">About Us</NavLink>

                </div> */}
                <div className="mb-1">
                  <NavLink to="/our-services" className="footer_link">
                    Our Services
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="executive_mob">
              <Accordion style={{ marginLeft: "-10px" }}>
                <Accordion.Item eventKey="0" className="new_foter_accoerdiaN">
                  <Accordion.Header>Executive Infusion</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      <NavLink
                        to="/home"
                        className="footer_link mob-footer_link"
                      >
                        Home
                      </NavLink>
                    </p>
                    {/* <p> <NavLink to="/faq" className="footer_link mob-footer_link">About Us</NavLink>
                    </p> */}
                    <p>
                      {" "}
                      <NavLink
                        to="/our-services"
                        className="footer_link mob-footer_link"
                      >
                        Our Services
                      </NavLink>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Col>
          <Col md={3} style={{ textAlign: "center" }}>
            <div className="polices_destop">
              <div style={{ textAlign: "left" }}>
                <div className="mb-1">
                  <h3 className="footer_link_heading">Policies</h3>
                  <NavLink to="/privacy" className="footer_link ">
                    Privacy Policy
                  </NavLink>
                </div>
                <div className="mb-1">
                  <NavLink to="/terms" className="footer_link">
                    Terms and Conditions
                  </NavLink>
                </div>
                <div className="mb-1">
                  <NavLink to="/faq" className="footer_link">
                    FAQs
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="polices_mob">
              <Accordion style={{ marginLeft: "-10px" }}>
                <Accordion.Item eventKey="0" className="new_foter_accoerdiaN">
                  <Accordion.Header>Polices</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      <NavLink
                        to="/privacy"
                        className="footer_link mob-footer_link"
                      >
                        Privacy Policy
                      </NavLink>
                    </p>
                    <p>
                      <NavLink
                        to="/terms"
                        className="footer_link mob-footer_link"
                      >
                        Terms and Conditions
                      </NavLink>
                    </p>
                    <p>
                      <NavLink
                        to="/faq"
                        className="footer_link mob-footer_link"
                      >
                        FAQs
                      </NavLink>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Col>

          <Col md={3} style={{ textAlign: "center" }}>
            <div className="desktop_vieew">
              <div style={{ textAlign: "left" }}>
                <h3
                  className="footer_link_heading1"
                  style={{
                    height: "24px",
                  }}
                >
                  <a
                    rel="noreferrer"
                    href="https://mail.google.com/mail/?view=cm&fs=1&to=info@executiveinfusion.com"
                    target="_blank"
                    className="footer_link_heading1"
                    style={{
                      marginLeft: "0",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <img
                      src={mail}
                      alt="mail"
                      style={{
                        height: "24px",
                        width: "24px",
                      }}
                    />
                    <span
                      className="footer_address"
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      info@executiveinfusion.com
                    </span>
                  </a>
                </h3>

                <h3
                  className="footer_link_heading1"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <img
                      src={phone}
                      alt="phone"
                      style={{
                        height: "24px",
                        width: "24px",
                      }}
                    />
                    <a
                      href="tel:+1 (313)-982-3220"
                      className="footer_link_heading1"
                      style={{
                        marginLeft: "0",
                        marginTop: "0",
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <span
                        className="footer_address"
                        style={{
                          marginBottom: "0.5rem",
                          maxWidth: "auto",
                        }}
                      >
                        +1 (313)-982-3220
                      </span>
                    </a>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={print}
                      alt="fax"
                      style={{
                        height: "24px",
                        width: "24px",
                      }}
                    />
                    <a
                      href="tel:+1 (313)-982-3221"
                      className="footer_link_heading1"
                      style={{
                        // marginLeft: "0",
                        marginTop: "0",
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <span
                        className="footer_address"
                        style={{
                          // marginBottom: "1rem",
                          maxWidth: "auto",
                        }}
                      >
                        +1 (313)-982-3221
                      </span>
                    </a>
                  </div>
                </h3>
                <h3
                  className="footer_link_heading1"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                  }}
                >
                  <img
                    src={map}
                    alt="map"
                    style={{
                      height: "24px",
                      width: "24px",
                    }}
                  />
                  <p
                    className="footer_address"
                    onClick={openMap}
                    style={{
                      maxWidth: "auto",
                    }}
                  >
                    17000 Executive Plaza Dr Ste 201, Dearborn, MI 48126
                  </p>
                </h3>

                <div className="logo_img_div1">
                  <a
                    rel="noreferrer"
                    href="https://www.facebook.com/executiveinfusionservice"
                    target="_blank"
                  >
                    <img src={facebok} alt="fb" />
                  </a>

                  <a
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/executive-infusion-services-llc/"
                    target="_blank"
                  >
                    <img src={linkedin} alt="linkdin" />
                  </a>

                  {/* <a href="https://www.google.com/">
                    <img src={google} alt="google" />
                  </a> */}

                  <a href={mailtoHref} rel="noreferrer" target="_blank">
                    <img src={email} alt="mail" />
                  </a>
                </div>
              </div>
            </div>
            <div className="mob_vieew">
              <div
                // style={{ width: "48%", float: "right" }}
                className="footerAdress"
              >
                {" "}
                <h3
                  className="footer_link_heading1"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // alignItems: "center",
                  }}
                >
                  <img
                    src={map}
                    alt="map"
                    style={{ height: "24px", width: "24px" }}
                  />
                  <p className="footer_address" onClick={openMap}>
                    17000 Executive Plaza Dr Ste 201,Dearborn, MI 48126
                  </p>
                </h3>
              </div>

              <div style={{ width: "48%", float: "left" }}>
                {/* <a href="tel:+1 (313) 928-3220" className='footer_link_heading1'>  */}

                {/* </a> */}
                <h3 className="footer_link_heading1">
                  <img
                    src={phone}
                    alt="phone"
                    style={{ width: "24px", height: "24px" }}
                  />
                  <a
                    href="tel:+1 (313)-982-3220"
                    className="footer_link_heading1"
                  >
                    +1 (313)-982-3220
                  </a>
                </h3>

                <h3 className="footer_link_heading1">
                  <img
                    src={print}
                    alt="fax"
                    style={{ width: "24px", height: "24px" }}
                  />
                  <a
                    href="tel: +1 (313)-9823221"
                    className="footer_link_heading1"
                  >
                    +1 (313)-982-3221
                  </a>
                </h3>
                <div
                // style={{ width: "48%", float: "left" }}
                >
                  {/* <a
                  href={mailtoHref}
                  rel="noreferrer"
                  target="_blank"
                  className="footer_link_heading1"
                ></a> */}
                  <h3
                    className="footer_link_heading1"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={mail}
                      alt="mail"
                      style={{ width: "24px", height: "24px" }}
                    />
                    <a
                      href={mailtoHref}
                      rel="noreferrer"
                      target="_blank"
                      // className="footer_link_heading1"
                      className="emailPhone"
                    >
                      {"  "}
                      info@executiveinfusion.com
                    </a>
                  </h3>
                </div>
              </div>

              <div className="logo_img_div1">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.facebook.com/executiveinfusionservice"
                >
                  <img src={facebok} alt="fb" />
                </a>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.linkedin.com/company/executive-infusion-services-llc/"
                >
                  <img src={linkedin} alt="ln" />
                </a>
                {/* <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.google.com/"
                >
                  <img src={google} alt="gg" />
                </a> */}
                <a href={mailtoHref} rel="noreferrer" target="_blank">
                  <img src={email} alt="mail" />
                </a>
              </div>
            </div>
          </Col>
          <Col md={3} style={{ textAlign: "center" }}>
            <Map location={location} zoomLevel={10} />
          </Col>
          <Container>
            <div className=" bottom_logo">
              <div className="logo_img_div">
                <img src={footer1} alt="footer1" />
                <img src={footer2} alt="footer2" />
                <img src={footer3} alt="footer3" />
              </div>
            </div>
          </Container>
        </Row>
      </Container>
      <div className="footer_Last p-2">
        <Row style={{ width: "96%", margin: "0px auto" }}>
          <Col md={6} className="new_Align">
            <div className="lagnuages">
              <NavLink to="/" className="footer_link1 me-5 text-light">
                ENGLISH
              </NavLink>
              <NavLink to="/" className="footer_link1 me-5 text-light">
                SPANISH
              </NavLink>
              <NavLink to="/" className="footer_link1 me-5 text-light">
                ARABIC
              </NavLink>
            </div>
          </Col>
          <div className="footer_line"></div>
          <Col
            md={5}
            lg={6}
            className="ms-md-auto mt-1 mt-md-0 mt-lg-0 mt-xl-0 mt-xxl-0  "
          >
            <div className="new_Align1">
              <p className="lagnuages">
                Executive Infusion © 2023 | Designed & Developed By{" "}
                <b
                  style={{
                    fontWeight: "900",
                    cursor: "pointer",
                  }}
                  onClick={routeChange}
                >
                  Xcentric Services
                </b>
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Footer;
