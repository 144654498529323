//added new forms
import GeneralReferral from "../../Componenets/assets/Executive Infusion Final Forms/1. General Referral Form.pdf";
import GIReferral from "../../Componenets/assets/Executive Infusion Final Forms/2. GI Referral Form.pdf";
import IronDeficiency from "../../Componenets/assets/Executive Infusion Final Forms/3. IRON Referral Form (Iron Deficiency Anemia referral Form).pdf";
import IVIGReferral from "../../Componenets/assets/Executive Infusion Final Forms/4. IVIG Referral Form.pdf";
import LupusReferral from "../../Componenets/assets/Executive Infusion Final Forms/5. Lupus Referral Form.pdf";
import OsteoporosisReferral from "../../Componenets/assets/Executive Infusion Final Forms/6. Osteoporosis Referral Form.pdf";
import Adakveo from "../../Componenets/assets/Executive Infusion Final Forms/7. Adakveo Referral Form (Adakveo).pdf";
import Aduhel from "../../Componenets/assets/Executive Infusion Final Forms/8. Aduhelm Referral Form (Aduhelm).pdf";
import Apretude from "../../Componenets/assets/Executive Infusion Final Forms/9. Apretude Referral Form (Apretude) copy.pdf";
import Avsola from "../../Componenets/assets/Executive Infusion Final Forms/10. Avsola Referral Form.pdf";
import Benlysta from "../../Componenets/assets/Executive Infusion Final Forms/11. Benlysta Referral Form.pdf";
import Bivigam from "../../Componenets/assets/Executive Infusion Final Forms/12.Bivigam Referral Form.pdf";
import Cabenuva from "../../Componenets/assets/Executive Infusion Final Forms/13. Cabenuva Referral Form (Cabenuva).pdf";
import Entyvio from "../../Componenets/assets/Executive Infusion Final Forms/14. Entyvio Referral Form (Entyvio).pdf";
import Evenity from "../../Componenets/assets/Executive Infusion Final Forms/15. Evenity Referral Form 0.pdf";
import Feraheme from "../../Componenets/assets/Executive Infusion Final Forms/16.Feraheme Referral Form.pdf";
import GammagardLiquid from "../../Componenets/assets/Executive Infusion Final Forms/17.Gammagard Liquid Referral Form.pdf";
import GammagardSD from "../../Componenets/assets/Executive Infusion Final Forms/18. Gammagard SD Referral Form.pdf";
import GamunexC from "../../Componenets/assets/Executive Infusion Final Forms/19.Gamunex C Referral Form.pdf";
import Ilumya from "../../Componenets/assets/Executive Infusion Final Forms/20. Ilumya Referral Form (Ilumya).pdf";
import INFeD from "../../Componenets/assets/Executive Infusion Final Forms/21.Infed Referral Form.pdf";
import Infliximab from "../../Componenets/assets/Executive Infusion Final Forms/22. INFLIXIMAB REFERRAL FORM.pdf";
import Injectafer from "../../Componenets/assets/Executive Infusion Final Forms/23.Injectafer Referral Form.pdf";
import Krystexxa from "../../Componenets/assets/Executive Infusion Final Forms/24. Krystexxa Referral Form (Krystexxa).pdf";
import Leqvio from "../../Componenets/assets/Executive Infusion Final Forms/25. Leqvio Referral Form (Leqvio).pdf";
import Monoferric from "../../Componenets/assets/Executive Infusion Final Forms/26.Monoferric Referral Form.pdf";
import Nucala from "../../Componenets/assets/Executive Infusion Final Forms/27. Nucala Referral Form (Nucala).pdf";
import Ocrevus from "../../Componenets/assets/Executive Infusion Final Forms/28. Ocrevus Referral Form (Ocrevus).pdf";
import Octagam from "../../Componenets/assets/Executive Infusion Final Forms/29.Octagam Referral Form.pdf";
import Onpattro from "../../Componenets/assets/Executive Infusion Final Forms/30. Onpattro Referral Form (Onpattro).pdf";
import Orencia from "../../Componenets/assets/Executive Infusion Final Forms/31. Orencia Referral Form (Orencia).pdf";
import Panzyga from "../../Componenets/assets/Executive Infusion Final Forms/32.Panzyga Referral Form.pdf";
import Privigen from "../../Componenets/assets/Executive Infusion Final Forms/33. Privigen Referral Form.pdf";
import Prolia from "../../Componenets/assets/Executive Infusion Final Forms/34. PROLIA ORDER FORM.pdf";
import Remicade from "../../Componenets/assets/Executive Infusion Final Forms/35. Remicade Referral Form (Remicade).pdf";
import Renflexis from "../../Componenets/assets/Executive Infusion Final Forms/36. INFUSION ORDERS RENFLEXIS.pdf";
import Riabni from "../../Componenets/assets/Executive Infusion Final Forms/37.Riabni Referral Form.pdf";
import Rituxan from "../../Componenets/assets/Executive Infusion Final Forms/38. INFUSION ORDERS RITUXAN.pdf";
import Rituximab from "../../Componenets/assets/Executive Infusion Final Forms/39. Rituximab Referral Form (Rituximab).pdf";
import Ruxience from "../../Componenets/assets/Executive Infusion Final Forms/40. INFUSION ORDERS RUXIENCE.pdf";
import Saphnelo from "../../Componenets/assets/Executive Infusion Final Forms/41. SAPHNELO.pdf";
import SimponiARIA from "../../Componenets/assets/Executive Infusion Final Forms/42. Simponi Aria Referral Form (Simponi-ARIA).pdf";
import Skyrizi from "../../Componenets/assets/Executive Infusion Final Forms/43. SKYRIZI.pdf";
import Soliris from "../../Componenets/assets/Executive Infusion Final Forms/44. Soliris Referral Form (Soliris).pdf";
import StelaraCrohns from "../../Componenets/assets/Executive Infusion Final Forms/45. Stelara Crohns Referral Form (Stelara-Crohns).pdf";
import PsoriaticArthritis from "../../Componenets/assets/Executive Infusion Final Forms/46. Stelara PsoArth Referral Form  Stelara (Psoriatic Arthritis & Plaque Psoriasis).pdf";
import UlcerativeColitis from "../../Componenets/assets/Executive Infusion Final Forms/47. Stelara Colitis Referral Form Stelara (Ulcerative Colitis).pdf";
import Tepezza from "../../Componenets/assets/Executive Infusion Final Forms/48. Tepezza Referral Form (Tepezza).pdf";
import Tezspire from "../../Componenets/assets/Executive Infusion Final Forms/49. Tezspire Referral Form (Tezspire).pdf";
import Truxima from "../../Componenets/assets/Executive Infusion Final Forms/50. INFUSION ORDERS TRUXIMA.pdf";
import Ultomiris from "../../Componenets/assets/Executive Infusion Final Forms/51. Ultomiris Referral Form (Ultomiris).pdf";
import Uplizna from "../../Componenets/assets/Executive Infusion Final Forms/52. Uplizna Referral Form (Uplizna).pdf";
import Venofer from "../../Componenets/assets/Executive Infusion Final Forms/53.Venofer Referral Form.pdf";
import Vyepti from "../../Componenets/assets/Executive Infusion Final Forms/54. VYEPTI Referral Form (Vyepti).pdf";
import Vyvgart from "../../Componenets/assets/Executive Infusion Final Forms/55. Vyvgart Referral Form (Vyvgart).pdf";
import Xolair from "../../Componenets/assets/Executive Infusion Final Forms/56. Xolair Referral Form (Xolair).pdf";
import ZoledronicAcid from "../../Componenets/assets/Executive Infusion Final Forms/57. INFUSION ORDERS ZOLEDRONIC ACID.pdf";
// import GIReferral from "../../Componenets/assets/Executive Forms _ First Half/2. GI Referral Form.pdf";
// import IronDeficiency from "../../Componenets/assets/Executive Forms _ First Half/3. IRON Referral Form (Iron Deficiency Anemia referral Form).pdf";
// import IVIGReferral from "../../Componenets/assets/Executive Forms _ First Half/4. IVIG Referral Form.pdf";
// import LupusReferral from "../../Componenets/assets/Executive Forms _ First Half/5. Lupus Referral Form.pdf";
// import OsteoporosisReferral from "../../Componenets/assets/Executive Forms _ First Half/6. Osteoporosis Referral Form.pdf";
// import Adakveo from "../../Componenets/assets/Executive Forms _ First Half/7. Adakveo Referral Form (Adakveo).pdf";
// import Aduhel from "../../Componenets/assets/Executive Forms _ First Half/8. Aduhelm Referral Form (Aduhelm).pdf";
// import Apretude from "../../Componenets/assets/Executive Forms _ First Half/9. Apretude Referral Form (Apretude).pdf";
// // import Avsola from "../../Componenets/assets/Executive Forms _ First Half/10. Remicade Referral Form (Avsola).pdf";
// import Avsola from "../../Componenets/assets/Executive Forms _ First Half/1. Avsola Referral Form.pdf";
// // import Benlysta from "../../Componenets/assets/Executive Forms _ First Half/11. Lupus Referral Form (Benlysta).pdf";
// import Benlysta from "../../Componenets/assets/Executive Forms _ First Half/2. Benlysta Referral Form.pdf";
// import Bivigam from "../../Componenets/assets/Executive Forms _ First Half/12. IVIG Referral Form (Bivigam).pdf";
// import Cabenuva from "../../Componenets/assets/Executive Forms _ First Half/13. Cabenuva Referral Form (Cabenuva).pdf";
// import Entyvio from "../../Componenets/assets/Executive Forms _ First Half/14. Entyvio Referral Form (Entyvio).pdf";
// import Evenity from "../../Componenets/assets/Executive Forms _ First Half/15. Osteoporosis Referral Form (Evenity).pdf";
// import Feraheme from "../../Componenets/assets/Executive Forms _ First Half/16. IRON Referral Form (Feraheme).pdf";
// import GammagardLiquid from "../../Componenets/assets/Executive Forms _ First Half/17. IVIG Referral Form (Gammagard Liquid).pdf";
// import GammagardSD from "../../Componenets/assets/Executive Forms _ First Half/18. IVIG Referral Form (Gammagard S-D).pdf";
// import GamunexC from "../../Componenets/assets/Executive Forms _ First Half/19. IVIG Referral Form (Gamunex-C).pdf";
// import Ilumya from "../../Componenets/assets/Executive Forms _ First Half/20. Ilumya Referral Form (Ilumya).pdf";
// import INFeD from "../../Componenets/assets/Executive Forms _ First Half/21. IRON Referral Form (INFeD).pdf";
// // import Infliximab from "../../Componenets/assets/Executive Forms _ First Half/22. Remicade Referral Form (Infliximab).pdf";
// import Infliximab from "../../Componenets/assets/Executive Forms _ First Half/9. INFLIXIMAB REFERRAL FORM.pdf";
// import Injectafer from "../../Componenets/assets/Executive Forms _ First Half/23. IRON Referral Form (Injectafer).pdf";
// import Krystexxa from "../../Componenets/assets/Executive Forms _ First Half/24. Krystexxa Referral Form (Krystexxa).pdf";
// import Leqvio from "../../Componenets/assets/Executive Forms _ First Half/25. Leqvio Referral Form (Leqvio).pdf";
// import Monoferric from "../../Componenets/assets/Executive Forms _ First Half/26. IRON Referral Form (Monoferric).pdf";
// import Nucala from "../../Componenets/assets/Executive Forms _ First Half/27. Nucala Referral Form (Nucala).pdf";
// import Ocrevus from "../../Componenets/assets/Executive Forms _ First Half/28. Ocrevus Referral Form (Ocrevus).pdf";
// import Octagam from "../../Componenets/assets/Executive Forms _ First Half/29. IVIG Referral Form (Octagam).pdf";
// import Onpattro from "../../Componenets/assets/Executive Forms _ First Half/30. Onpattro Referral Form (Onpattro).pdf";
// import Orencia from "../../Componenets/assets/Executive Forms _ Second Half/31. Orencia Referral Form (Orencia).pdf";
// import Panzyga from "../../Componenets/assets/Executive Forms _ Second Half/32. IVIG Referral Form (Panzyga).pdf";
// import Privigen from "../../Componenets/assets/Executive Forms _ Second Half/33. IVIG Referral Form (Privigen).pdf";
// // import Prolia from "../../Componenets/assets/Executive Forms _ Second Half/34. Osteoporosis Referral Form (Prolia).pdf";
// import Prolia from "../../Componenets/assets/Executive Forms _ Second Half/3. PROLIA ORDER FORM.pdf";
// import Remicade from "../../Componenets/assets/Executive Forms _ Second Half/35. Remicade Referral Form (Remicade).pdf";
// // import Renflexis from "../../Componenets/assets/Executive Forms _ Second Half/36. Remicade Referral Form (Renflexis).pdf";
// import Renflexis from "../../Componenets/assets/Executive Forms _ Second Half/4. INFUSION ORDERS RENFLEXIS.pdf";
// import Riabni from "../../Componenets/assets/Executive Forms _ Second Half/37. Rituximab Referral Form (Riabni).pdf";
// // import Rituxan from "../../Componenets/assets/Executive Forms _ Second Half/38. Rituximab Referral Form (Rituxan).pdf";
// import Rituxan from "../../Componenets/assets/Executive Forms _ Second Half/5. INFUSION ORDERS RITUXAN.pdf";
// import Rituximab from "../../Componenets/assets/Executive Forms _ Second Half/39. Rituximab Referral Form (Rituximab).pdf";
// // import Ruxience from "../../Componenets/assets/Executive Forms _ Second Half/40. Rituximab Referral Form (Ruxience).pdf";
// import Ruxience from "../../Componenets/assets/Executive Forms _ Second Half/6. INFUSION ORDERS RUXIENCE.pdf";
// // import Saphnelo from "../../Componenets/assets/Executive Forms _ Second Half/41. Lupus Referral Form (Saphnelo).pdf";
// import Saphnelo from "../../Componenets/assets/Executive Forms _ Second Half/10. SAPHNELO.pdf";
// import SimponiARIA from "../../Componenets/assets/Executive Forms _ Second Half/42. Simponi Aria Referral Form (Simponi-ARIA).pdf";
// // import Skyrizi from "../../Componenets/assets/Executive Forms _ Second Half/43. GI Referral Form (Skyrizi).pdf";
// import Skyrizi from "../../Componenets/assets/Executive Forms _ Second Half/11. SKYRIZI.pdf";
// import Soliris from "../../Componenets/assets/Executive Forms _ Second Half/44. Soliris Referral Form (Soliris).pdf";
// import StelaraCrohns from "../../Componenets/assets/Executive Forms _ Second Half/45. Stelara Crohns Referral Form (Stelara-Crohns).pdf";
// import PsoriaticArthritis from "../../Componenets/assets/Executive Forms _ Second Half/46. Stelara PsoArth Referral Form  Stelara (Psoriatic Arthritis & Plaque Psoriasis).pdf";
// import UlcerativeColitis from "../../Componenets/assets/Executive Forms _ Second Half/47. Stelara Colitis Referral Form Stelara (Ulcerative Colitis).pdf";
// import Tepezza from "../../Componenets/assets/Executive Forms _ Second Half/48. Tepezza Referral Form (Tepezza).pdf";
// import Tezspire from "../../Componenets/assets/Executive Forms _ Second Half/49. Tezspire Referral Form (Tezspire).pdf";
// // import Truxima from "../../Componenets/assets/Executive Forms _ Second Half/50. Rituximab Referral Form (Truxima).pdf";
// import Truxima from "../../Componenets/assets/Executive Forms _ Second Half/7. INFUSION ORDERS TRUXIMA.pdf";
// import Ultomiris from "../../Componenets/assets/Executive Forms _ Second Half/51. Ultomiris Referral Form (Ultomiris).pdf";
// import Uplizna from "../../Componenets/assets/Executive Forms _ Second Half/52. Uplizna Referral Form (Uplizna).pdf";
// import Venofer from "../../Componenets/assets/Executive Forms _ Second Half/53. IRON Referral Form (Venofer).pdf";
// import Vyepti from "../../Componenets/assets/Executive Forms _ Second Half/54. VYEPTI Referral Form (Vyepti).pdf";
// import Vyvgart from "../../Componenets/assets/Executive Forms _ Second Half/55. Vyvgart Referral Form (Vyvgart).pdf";
// import Xolair from "../../Componenets/assets/Executive Forms _ Second Half/56. Xolair Referral Form (Xolair).pdf";
// // import ZoledronicAcid from "../../Componenets/assets/Executive Forms _ Second Half/57. Osteoporosis Referral Form (Zoledronic Acid).pdf";
// import ZoledronicAcid from "../../Componenets/assets/Executive Forms _ Second Half/8. INFUSION ORDERS ZOLEDRONIC ACID.pdf";

export const Array = [
  { name: "Adakveo®", id: 1, file: Adakveo },
  { name: "Aduhelm", id: 2, file: Aduhel },
  { name: "Apretude", id: 3, file: Apretude },
  { name: "Avsola®", id: 4, file: Avsola },
  { name: "Benlysta", id: 5, file: Benlysta },
  { name: "Bivigam®", id: 6, file: Bivigam },
  { name: "Cabenuva", id: 7, file: Cabenuva },
  { name: "Entyvio®", id: 8, file: Entyvio },
  { name: "Evenity®", id: 9, file: Evenity },
  { name: "Feraheme®", id: 10, file: Feraheme },
  { name: "Gammagard® Liquid", id: 11, file: GammagardLiquid },
  { name: "Gammagard® S/D", id: 12, file: GammagardSD },
  { name: "Gamunex®-C", id: 13, file: GamunexC },
  { name: "General Referral Form", id: 14, file: GeneralReferral },
  { name: "GI Referral Form", id: 15, file: GIReferral },
  { name: "Ilumya®", id: 16, file: Ilumya },
  { name: "INFeD®", id: 17, file: INFeD },
  { name: "Infliximab", id: 18, file: Infliximab },
  { name: "Injectafer®", id: 19, file: Injectafer },
  {
    name: "Iron Deficiency Anemia Referral Form",
    id: 20,
    file: IronDeficiency,
  },
  { name: "IVIG Referral Form", id: 21, file: IVIGReferral },
  { name: "Krystexxa®", id: 22, file: Krystexxa },
  { name: "Leqvio®", id: 23, file: Leqvio },
  { name: "Lupus Referral Form", id: 24, file: LupusReferral },
  { name: "Monoferric®", id: 25, file: Monoferric },
  { name: "Nucala", id: 26, file: Nucala },
  { name: "Ocrevus", id: 27, file: Ocrevus },
  { name: "Octagam®", id: 28, file: Octagam },
  { name: "Onpattro®", id: 29, file: Onpattro },
  { name: "Orencia®", id: 30, file: Orencia },
  { name: "Osteoporosis Referral Form", id: 31, file: OsteoporosisReferral },
  { name: "Panzyga®", id: 32, file: Panzyga },
  { name: "Privigen®", id: 33, file: Privigen },
  { name: "Prolia®", id: 34, file: Prolia },
  { name: "Remicade®", id: 35, file: Remicade },
  { name: "Renflexis®", id: 36, file: Renflexis },
  { name: "Riabni™", id: 37, file: Riabni },
  { name: "Rituxan®", id: 38, file: Rituxan },
  { name: "Rituximab", id: 39, file: Rituximab },
  { name: "Ruxience®", id: 40, file: Ruxience },
  { name: "Saphnelo™", id: 41, file: Saphnelo },
  { name: "Simponi-ARIA®", id: 42, file: SimponiARIA },
  { name: "Skyrizi®", id: 43, file: Skyrizi },
  { name: "Soliris®", id: 44, file: Soliris },
  { name: "Stelara® (Crohn's)", id: 45, file: StelaraCrohns },

  { name: "Stelara® (Ulcerative Colitis)", id: 47, file: UlcerativeColitis },
  { name: "Tepezza®", id: 48, file: Tepezza },
  {
    name: "Stelara® (Psoriatic Arthritis & Plaque Psoriasis)",
    id: 46,
    file: PsoriaticArthritis,
  },
  { name: "Tezspire®", id: 49, file: Tezspire },
  { name: "Truxima®", id: 50, file: Truxima },
  { name: "Ultomiris®", id: 51, file: Ultomiris },
  { name: "Uplizna®", id: 52, file: Uplizna },
  { name: "Venofer®", id: 53, file: Venofer },
  { name: "Vyepti®", id: 54, file: Vyepti },
  { name: "Vyvgart®", id: 55, file: Vyvgart },
  { name: "Xolair®", id: 56, file: Xolair },
  { name: "Zoledronic Acid", id: 57, file: ZoledronicAcid },
];
