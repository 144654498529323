import React from 'react'
import "./TermComponent.css"
const TermComponent = () => {
    return (
        <>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className='container privacy'>
                <div className='row privacy-div'>
                    <h1 className='mt-2 privacy-title'>Terms Of Use</h1>
                    <hr className='my-5 hrColor' />
                    <p className='privacy-p'>Welcome to www.executiveinfusion.com, which consists of web pages that are owned, maintained, and operated by Executive Infusion.The terms such as “our”, “us” or “we” refer to Executive Infusion and entities affiliated with it.Your access to the website and the content on it is based on your acceptance of the Terms of Use. </p>

                    <p className='privacy-p mt-2'>All the content, material, and information on this website is only for informational purposes and does not intend to serve as a substitute for the consultation, diagnosis, or/and medical treatment by a healthcare provider. If you have any medical emergency, immediately call your doctor or provider. Your reliance on the information that we have provided on this website is solely at your own risk. Executive Infusion takes no responsibility for the consequences that result from the use of the information given on the website.</p>
                    <hr className='my-3' />
                    <div>
                        <h3 className='my-3 privacy-h3'>Electronic Communication</h3>
                        <p className='privacy-p'>Visiting this website or providing your personal information to Executive Infusion establishes electronic communication. By doing so, you consent to engage in electronic communication with us and you also agree that the disclosures, notices, and agreements that we provide satisfy the legal requirements of having such communications in writing.</p>
                    </div>
                    <hr className='my-3' />
                    <h3 className='my-3 privacy-h3'>Prohibited Or Unlawful Use Of Intellectual Property</h3>
                    <p className='privacy-p'>As a condition to using this website, as a user, you warrant to Executive Infusion that this website will not be used for any prohibited or unlawful purpose. You will not use the website in any manner that damages, disables, and overburdens the website or interfere with any other party’s use of the website.</p>
                    <p className='privacy-p mt-2'>Content included as a part of the website, such as images, logos, graphics, text, as well as compilations are the property of Executive Infusion and protected by copyrights plus other laws implemented for the protection of proprietary rights and intellectual property. You agree to abide by all the proprietary plus copyright notices and restrictions on the website content. </p>
                    <hr className='my-3' />
                </div>
            </div>
        </>

    )
}

export default TermComponent
