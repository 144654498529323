import React from 'react'
import '../Faq/faq.css'

import Accordion from 'react-bootstrap/Accordion';
const Faq = () => {
  return (
    <>
      <div className='faaq_main'>
        <div className='fad_data'>
          <p className='faq_headaing'>Frequently Asked Questions </p>

          <div>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Is The Infusion Process Painful?</Accordion.Header>
                <Accordion.Body>
                Our clinicians and nurse make sure that the infusion treatment is as painless as possible for every patient. We use the latest technology and tools for the infusions to ensure maximum safety and comfort.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>How Much Does An Infusion Therapy Costs?</Accordion.Header>
                <Accordion.Body>
                To get a quote on the total therapy cost, please contact us as it varies from one patient to another. Also, note that the cost of medications is not included in the infusion service fee and is to be paid separately.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>How Long Will My Infusion Therapy Take?</Accordion.Header>
                <Accordion.Body>
                Mostly, an infusion takes less than 30 minutes. However, for patients getting antibiotics or iron infusion, it takes one hour as the patient is required to stay under observation for additional 30 minutes post-infusion. Specialty medications can take from 30 minutes to a whole day for infusion. For an estimate of your therapy time, consult with our specialists.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Can I Drink Or Eat Before The Infusion?</Accordion.Header>
                <Accordion.Body>
                In most cases, there are no dietary restrictions for the patient before their infusion. However, you might have to hydrate or fast for a blood draw before the infusion.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Do You Accept Insurances?</Accordion.Header>
                <Accordion.Body>
                Yes, we do accept most of the insurance. However, do give us a call to get your coverage verified and estimate the infusion care cost. Be sure to inform us if there are any changes in your insurance before you visit us on the infusion day. 
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>What Should I Bring Along To The Infusion Center?</Accordion.Header>
                <Accordion.Body>
                Always bring your ID card and current insurance card when visiting Executive Infusion’s ambulatory center to get an infusion. 
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>Why Should I Get My Medications From Executive Infusion?</Accordion.Header>
                <Accordion.Body>
                We recommend getting medications from us because of several reasons. Firstly, injectable infusions and specialty drugs and not available easily at local pharmacies, and sourcing them is difficult. Furthermore, storing such medications under required conditions is also extremely important to ensure efficacy and safety. Hence, we recommend that the patients get their medications from us for convenience and safety. 
                </Accordion.Body>
              </Accordion.Item>
            
            </Accordion>
          </div>

        </div>
      </div>
    </>
  )
}

export default Faq