
import "./AppointmentBanner.css"
const AppointmentBanner = () => {

    return (
        <>

            <div className='appointment-banner'>
                <p className='appointment-title'>Book an appointment</p>
            </div>
        </>
    )
}

export default AppointmentBanner
