export const AmbulatoryData = [
    {
        title: "Smooth Administrative Care",
        subtitle: "We have streamlined processes and a team that removes administrative hurdles to begin patient treatments without delays."
    },
    {
        title: "Personalized Healthcare Plans",
        subtitle: "By offering payor coverage to patients, we provide them access to personalized healthcare plans and reduce their out-of-the-pocket expenses."
    },
    {
        title: "24/7 Patient Support",
        subtitle: "Our dedicated clinicians and nurses are always available to answer the questions of patients and their families while providing effective care."
    }
]