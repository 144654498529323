import React from 'react'

const BackArrowBlkSvg = ({ stroke }) => {
    return (
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.875 0.75L1.625 6L6.875 11.25" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default BackArrowBlkSvg
