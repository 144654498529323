import React from 'react'

const Providers = ({ stroke }) => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.39967 2.10698C4.40294 2.16458 4.42424 2.21969 4.46055 2.26451C4.49687 2.30934 4.54636 2.34161 4.60203 2.35676C4.6577 2.37191 4.71672 2.36916 4.77074 2.34892C4.82476 2.32867 4.87105 2.29195 4.90305 2.24395C4.93505 2.19595 4.95114 2.1391 4.94905 2.08144C4.94696 2.02379 4.9268 1.96826 4.8914 1.9227C4.85601 1.87714 4.80719 1.84387 4.75184 1.82758C4.6965 1.8113 4.63743 1.81284 4.58301 1.83198H3.66634C3.18011 1.83198 2.7138 2.02513 2.36998 2.36895C2.02616 2.71277 1.83301 3.17908 1.83301 3.66531V8.24864C1.83301 9.70734 2.41247 11.1063 3.44392 12.1377C4.47537 13.1692 5.87432 13.7486 7.33301 13.7486C8.7917 13.7486 10.1906 13.1692 11.2221 12.1377C12.2535 11.1063 12.833 9.70734 12.833 8.24864V3.66531C12.833 3.17908 12.6399 2.71277 12.296 2.36895C11.9522 2.02513 11.4859 1.83198 10.9997 1.83198H10.083C10.065 1.85003 10.0506 1.87147 10.0409 1.89506C10.0311 1.91866 10.0261 1.94394 10.0261 1.96948C10.0261 1.99501 10.0311 2.0203 10.0409 2.04389C10.0506 2.06749 10.065 2.08892 10.083 2.10698C10.1011 2.12503 10.1225 2.13936 10.1461 2.14913C10.1697 2.1589 10.195 2.16393 10.2205 2.16393C10.246 2.16393 10.2713 2.1589 10.2949 2.14913C10.3185 2.13936 10.34 2.12503 10.358 2.10698" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.33301 13.75V14.6667C7.33301 16.1254 7.91247 17.5243 8.94392 18.5558C9.97537 19.5872 11.3743 20.1667 12.833 20.1667V20.1667C14.2917 20.1667 15.6906 19.5872 16.7221 18.5558C17.7535 17.5243 18.333 16.1254 18.333 14.6667V11" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.3328 10.9948C19.3454 10.9948 20.1662 10.174 20.1662 9.16146C20.1662 8.14894 19.3454 7.32812 18.3328 7.32812C17.3203 7.32812 16.4995 8.14894 16.4995 9.16146C16.4995 10.174 17.3203 10.9948 18.3328 10.9948Z" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default Providers
