import React, { useCallback, useRef, useState } from "react";
import "./Map.css";
import GoogleMapReact from "google-map-react";
import LocationPin from "./LocationPin";

const Map = ({ location, zoomLevel }) => {
  const [center, setCenter] = useState({
    lat: location.lat,
    lng: location.lng,
  });
  const refMap = useRef(null);
  const handleBoundsChanged = () => {
    const mapCenter = refMap.current.getCenter(); //get map center
    setCenter(mapCenter);
  };
  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: { lat: 42.32569, lng: -83.21252 },
      map,
      title: "Hello World!",
    });
    return marker;
  };
  const handleClick = () => {
    console.log(location.lat, location.lng, "lat, lng");
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lng}`;
    window.open(googleMapsUrl, "_blank");
  };
  return (
    <div className="map ">
      <div className="google-map" onClick={handleClick}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBgOYQy8cZHW3BnTiS6-KOwEvClnCEsg_k" }}
          defaultCenter={{
            lat: 42.32569,
            lng: -83.21252,
          }}
          defaultZoom={10}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        ></GoogleMapReact>
      </div>
    </div>
  );
};

export default Map;
