import React, { useEffect } from 'react'
import Service from '../Servicess/Service'
import WellNessDivs from '../WellNessDivs/WellNessDivs'
import Benifits from '../Benifits/Benifits'
import FlexDiv from '../FlexDiv/FlexDiv'
import { useParams } from 'react-router-dom'


const ServicesLayout = ({ data, Layout, reviewData }) => {
    let { subservice } = useParams();
    const obj = data[subservice]
    console.log("obj in services page is", obj)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return (
        <>
            <Layout reviewData={reviewData}>
                <Service services_title1={obj.services_title1} services_title2={obj.services_title2}
                    services_subtitle={obj.services_subtitle} services_btn_text={obj.services_btn_text}
                    servicesBgClass={obj.servicesBgClass}
                    servicesBtnClass={obj.servicesBtnClass} services_headingClass={obj.services_headingClass}
                    ambulatoryMobileBgClass={obj.ambulatoryMobileBgClass} file={obj.file}
                />
                <WellNessDivs welltitle1={obj.welltitle1} welltitle2={obj.welltitle2} wellsubtitle={obj.wellsubtitle} wellimage={obj.wellimage} />
                <Benifits benifitsTitle={obj.benifitsTitle} benifitsSubTitle={obj.benifitsSubTitle} array={obj.array} />
                <FlexDiv flexTitle1={obj.flexTitle1} flexTitle2={obj.flexTitle2} flexTitle3={obj.flexTitle3} flexsubTitle={obj.flexsubTitle} flexSvg={obj.flexSvg}
                    flextext1={obj.flextext1} flextext2={obj.flextext2} flextext3={obj.flextext3} flexRightImage={obj.flexRightImage} flexWebBgImgClass={obj.flexWebBgImgClass}
                    flexMobileBg={obj.flexMobileBg} file={obj.file} services_form_text={obj.services_form_text} services_title1={obj.services_title1}
                />
            </Layout>


        </>
    )
}

export default ServicesLayout
