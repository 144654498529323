export const compoundingData = [
    {
        title: "Sterile Compounding",
        subtitle: "Medications are compounded according to the sterilization regulations for the administration of patients."
    },
    {
        title: "Non-Sterile Compounding ",
        subtitle: "We prepare non-sterile compounded medications under a closely monitored environment."
    },
    {
        title: "Equipped For Complexity ",
        subtitle: "We have the technology & training to formulate complex compounds along with simple compounds. "
    }
]