// import React, { useState } from "react";
// import "../Navbar/navbar.css";
// import {
//   Nav,
//   Navbar,
//   Container,
// } from "react-bootstrap";
// import { NavLink } from 'react-router-dom'; // import the NavLink component

// import { useNavigate } from "react-router-dom";

// import logo from "../assets/images/newlogo.png";
// import AOS from "aos";
// import "aos/dist/aos.css";
// // import Phone from "../assets/svgs/Phone";
// import World from "../assets/svgs/World";
// import User from "../assets/svgs/User";
// import Search from "../assets/svgs/Search";
// import cross from '../assets/images/cancel1.png'
// import phone from '../assets/images/whitePhonePic.png'
// AOS.init();
// const Header = () => {
//   const [show, setshow] = useState(true);
//   const [search, setSearch] = useState(true)
//   let navigate = useNavigate()
//   const navigate_ = (url) => {
//     navigate(url)
//   }
//   return (
//     <>
//       <div className="header_new">
//         <div className="d-flex align-items-center justify-content-center">
//           <a href="tel:+1 (313) 928-3220" className='whitePhonediv'> <img src={phone} alt="whitePhonepic" /> </a>
//           <span className="ms-1 banner-txt">
//             <a href="tel:+1 (313) 928-3220">+1 (313) 928-3220</a>
//           </span>
//         </div>
//         <div className="d-flex align-items-center justify-content-center">
//           <World />
//           <select name="" className="navdropdpwn languageSelect">
//             <option>EN</option>
//             <option>Ar</option>
//             <option>Sp</option>
//           </select>
//         </div>
//         <div className="d-flex align-items-center justify-content-center">
//           <User />
//           <span className="ms-1 banner-txt" onClick={() => navigate_("/paybill")}>Pay My Bill</span>
//         </div>
//         <div className="d-flex align-items-center justify-content-center">
//           <Search />
//         </div>
//       </div>
//       <div className={
//         search === true ? "saerch_mob1" : "saerch_mob"
//       }>

//         <div><Search />
//           <input type="text" placeholder="search" className="search_input" /></div>
//         <div><img src={cross} onClick={() => setSearch(true)} alt="cross" /> </div>
//       </div>

//       <div className={
//         search === true ? "header_new1" : "header_new2"
//       } style={{ justifyContent: "space-between" }}>
//         <div className="d-flex align-items-center justify-content-center">
//           <World />
//           <select name="" className=" navdropdpwn languageSelect">
//             <option>EN</option>
//             <option>Ar</option>
//             <option>Sp</option>
//           </select>
//           <div className="d-flex align-items-center justify-content-center ms-2"
//           // onClick={() => setSearch(!search)}
//           >
//             <Search />
//           </div>
//         </div>

//         {/* <div className="d-flex align-items-center justify-content-center">
//           <User />
//           <span className="ms-1 banner-txt me-3">Pay My Bill</span>
//           <Phone />
//         </div> */}
//         <span className="ms-1 banner-txt " onClick={() => navigate_("/paybill")} >Pay My Bill</span>
//         <a href="tel:+1 (313) 928-3220" className='whitePhonediv ms-4'> <img src={phone} alt="whitePhonepic" /> </a>
//       </div>
//       <Navbar expand="md" id="navbar">
//         <Container fluid>
//           <Navbar.Brand href="#" data-aos="fade-up" data-aos-duration="1000">
//             <img src={logo} className="nav_logo" onClick={() => navigate_("/home")} alt="logo" />
//           </Navbar.Brand>
//           <Navbar.Toggle
//             aria-controls="navbarScroll"
//             onClick={() => setshow(!show)}
//           >
//             <span
//               className={
//                 show === true ? "navbar-toggler-icon" : "navbar-toggler-icon1"
//               }
//             ></span>
//           </Navbar.Toggle>
//           <Navbar.Collapse id="navbarScroll">
//             <Nav
//               className="me-auto my-2 my-lg-0"
//               style={{ maxHeight: "100px" }}
//               navbarScroll
//             ></Nav>
//             <div
//               className="nav_data"
//               data-aos="fade-up"
//               data-aos-duration="1000"
//             >
//               <Nav.Link as={NavLink} to="/home" className="linee1" >
//                 Home

//               </Nav.Link>
//               <Nav.Link as={NavLink} to="/" className="linee2">
//                 About Us
//               </Nav.Link>
//               <Nav.Link as={NavLink} to="/raferal" className="linee2">
//                 Refer A Patient
//               </Nav.Link>
//               <Nav.Link as={NavLink} to="/payer" className="linee2">
//                 Payer
//               </Nav.Link>
//               <Nav.Link as={NavLink} to="/appointment" className="linee2">
//                 Book An Apppointment
//               </Nav.Link>
//             </div>
//           </Navbar.Collapse>
//         </Container>
//       </Navbar>
//     </>
//   );
// };

// export default Header;

import React, { useState, useEffect, useRef } from "react";
import "../Navbar/navbar.css";
import { Nav, Navbar, Container } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom"; // import the NavLink component

import logo from "../assets/images/logo.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import Phone from "../assets/svgs/Phone";
import World from "../assets/svgs/World";
import User from "../assets/svgs/User";
import Search from "../assets/svgs/Search";
import cross from "../assets/images/cancel1.png";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import NavbarDropdown from "./NavbarDropdown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
AOS.init();
const Header = () => {
  const navbarRef = useRef(null);
  const navigates = useNavigate();
  const [show, setshow] = useState(true);
  const [search, setSearch] = useState(true);
  const [dropdown, setDropdown] = useState(false);
  const [expanded, setExpanded] = useState();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isAccordionOpenProvider, setIsAccordionOpenProvider] = useState(false);

  let navigate = useNavigate();
  const navigate_ = (url) => {
    navigate(url);
  };
  const location = useLocation();
  const url = location.pathname;
  console.log(dropdown, "dropdown");
  const handleNavigate = (data) => {
    navigates("/appointment", { state: { data: data } });
  };
  const handleToggle = () => {
    setshow(!show);
    setExpanded(!expanded);
    console.log(show ? "open" : "closed");
  };
  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
    console.log("Accordion is now", isAccordionOpen ? "closed" : "open");
  };
  const handleAccordionToggleProvider = () => {
    setIsAccordionOpenProvider(!isAccordionOpenProvider);
    console.log(
      "Accordion is now",
      isAccordionOpenProvider ? "closed" : "open"
    );
  };
  // useEffect(() => {
  //   const handleClick = (event) => {
  //     if (isAccordionOpen && !event.target.closest(".accordion")) {
  //       // Close the accordion
  //       document.getElementById("accordionToggleButton").click();
  //     }
  //   };

  //   document.addEventListener("click", handleClick);

  //   return () => {
  //     document.removeEventListener("click", handleClick);
  //   };
  // }, [isAccordionOpen]);
  useEffect(() => {
    const handleClick = (event) => {
      const isOutsideAccordionExample =
        !event.target.closest("#accordionExample");
      const isOutsideAccordionExampleProvider = !event.target.closest(
        "#accordionExampleProvider"
      );
      if (isAccordionOpen && isOutsideAccordionExample) {
        document.getElementById("accordionToggleButton").click();
      }
      if (isAccordionOpenProvider && isOutsideAccordionExampleProvider) {
        document.getElementById("accordionToggleButtonProvider").click();
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [isAccordionOpen, isAccordionOpenProvider]);
  useEffect(() => {
    const handleClick = (event) => {
      const isOutsideNavbar = !event.target.closest(".navbar");

      if (isOutsideNavbar) {
        setExpanded(false);
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <>
      {/* <div className="header_new"> */}
      {/* <div className="d-flex align-items-center justify-content-center">
          <Phone />
          <span className="ms-1 banner-txt">
            <a href="tel:+1 (313) 928-3220">+1 (313) 928-3220</a>
          </span>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <World />
          <select name="" className="ms-1 navdropdpwn">
            <option>EN</option>
            <option>Ar</option>
            <option>Sp</option>
          </select>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <User />
          <span className="ms-1 banner-txt">Pay My Bill</span>
        </div> */}
      {/* <div className="d-flex align-items-center justify-content-center">
          <Search />
        </div>
      </div> */}
      {/* <div className={search === true ? "saerch_mob1" : "saerch_mob"}>
        <div>
          <Search />
          <input type="text" placeholder="search" className="search_input" />
        </div>
        <div>
          <img src={cross} onClick={() => setSearch(true)} />{" "}
        </div>
      </div> */}

      {/* <div
        className={search === true ? "header_new1" : "header_new2"}
        style={{ justifyContent: "space-between" }}
      > */}
      {/* <div className="d-flex align-items-center justify-content-center"> */}
      {/* <World />
          <select name="" className="ms-1 navdropdpwn">
            <option>EN</option>
            <option>Ar</option>
            <option>Sp</option>
          </select> */}
      {/* <div
            className="d-flex align-items-center justify-content-center ms-2"
            onClick={() => setSearch(!search)}
          >
            <Search />
          </div>
        </div> */}

      {/* <div className="d-flex align-items-center justify-content-center">
          <User />
          <span className="ms-1 banner-txt me-3">Pay My Bill</span>
          <Phone />
        </div> */}
      {/* </div> */}
      <Navbar expand="md" id="navbar" ref={navbarRef}>
        <Container fluid>
          <Navbar.Brand href="/" data-aos="fade-up" data-aos-duration="1000">
            <img
              src={logo}
              className="nav_logo"
              onClick={() => navigate_("/home")}
              alt="logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" onClick={handleToggle}>
            <span
              className={
                expanded === true
                  ? "navbar-toggler-icon1"
                  : "navbar-toggler-icon"
              }
            ></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="navbarScroll" in={expanded}>
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            ></Nav>
            <div
              className="nav_data"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {/* <Nav.Link as={NavLink} to="/home" className="linee1">
                Home
              </Nav.Link>
              <Nav.Link as={NavLink} to="/home" className="linee2">
                About Us
              </Nav.Link> */}
              {/* <Nav.Link as={NavLink} to="/raferal" className="linee2">
                {" "}
                Refer A Patient
              </Nav.Link> */}
              <div
                class="accordion remove-border"
                id="accordionExample"
                style={{ marginTop: "-1%" }}
              >
                <div
                  class="accordion-item navbarAlignment"
                  style={{ background: "transparent" }}
                >
                  <h2
                    class="accordion-header customAccordionHeader"
                    id="headingOne"
                    // style={{ marginBottom: "-17%" }}
                  >
                    <button
                      // class="accordion-button collapsed buttonHeight"
                      id="accordionToggleButton"
                      className={`accordion-button collapsed buttonHeight ${
                        !isAccordionOpen ? "openMargin" : "closedMargin"
                      }`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      data-bs-parent="#accordionExample"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                      style={{
                        background: "transparent",
                        marginTop: "1%",
                      }}
                      onClick={handleAccordionToggle}
                    >
                      <Nav.Link
                        // as={NavLink}
                        // to={{
                        //   pathname: "/appointment",
                        //   state: { name: "patient" },
                        // }}
                        className="linee2"
                      >
                        <p
                          onClick={() =>
                            navigates("/appointment", {
                              state: { data: "patients" },
                            })
                          }
                          className="providerText"
                        >
                          Patients
                        </p>
                      </Nav.Link>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    style={{
                      width: "max-content",
                      marginLeft: "5%",
                      // overflow: "auto", // Add overflow property to enable scrolling if necessary
                    }}
                  >
                    <div class="accordion-body custom-accordion-body">
                      <ScrollLink
                        to="Contact"
                        className="linee2 nav-link refferal"
                        style={{
                          cursor: "pointer",
                          height: "max-content",
                          // backgroundColor: "#fff",
                          borderRadius: "14px",
                        }}
                      >
                        <p onClick={() => setExpanded(!expanded)}>Contact Us</p>
                      </ScrollLink>
                    </div>
                    <br />
                    <div class="accordion-body custom-accordion-body">
                      <Nav.Link
                        as={NavLink}
                        to={{
                          pathname: "/our-services",
                          state: { name: "patient" },
                        }}
                        className="refferal mobileMarginFix "
                        style={{
                          // backgroundColor: "#fff",
                          borderRadius: "14px",
                        }}
                      >
                        <p
                          className="ourService"
                          // style={{ borderBottom: "2px solid #D3D3D3" }}
                        >
                          Our Services
                        </p>
                      </Nav.Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* <Nav.Link
                as={NavLink}
                to={{
                  pathname: "/appointment",
                  state: { name: "patient" },
                }}
                className="linee2"
              >
                Patients
              </Nav.Link> */}
              {/* <ScrollLink to="Contact" className="linee2 nav-link">
                Contact Us
              </ScrollLink> */}
              {/* <Nav.Link
                as={NavLink}
                to={{
                  pathname: "/our-services",
                  state: { name: "patient" },
                }}
                className="linee2"
              >
                Our Services
              </Nav.Link> */}
              {/* <div className="linee3">
                <span
                  onClick={() =>
                    navigates("/appointment", {
                      state: { name: "provider" },
                    })
                  }
                >
                  Providers
                </span>
                <KeyboardArrowDownIcon
                  onClick={() => setDropdown((prev) => !prev)}
                  sx={{ cursor: "pointer" }}
                />
                {dropdown && (
                  <NavbarDropdown
                    submenus={[
                      { title: "Submit a Referral", url: "/submit-a-referral" },
                    ]}
                  />
                )}
              </div> */}
              {/* <Accordion
                sx={{
                  width: { lg: "15%", md: "15%", xs: "100%" },
                  background: "transparent",
                  boxShadow: "none",
                  height: "50px",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ marginTop: "12px" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ margin: "-12px" }}
                >
                  <Nav.Link
                    as={NavLink}
                    to={{
                      pathname: "/appointment",
                      state: { name: "provider" },
                    }}
                    // className="linee2"
                  >
                    Providers
                  </Nav.Link>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    width: "max-content",
                    backgroundColor: {
                      lg: "transparent",
                      md: "transparent",
                      xs: "#fff",
                    },
                    marginLeft: { lg: "-30%", xs: 0 },
                  }}
                >
                  <Nav.Link
                    as={NavLink}
                    to="/submit-a-referral"
                    // className="linee2"
                  >
                    {" "}
                    Submit a Referral
                  </Nav.Link>
                </AccordionDetails>
              </Accordion> */}
              <div
                class="accordion remove-border"
                id="accordionExample"
                // style={{ marginTop: "-4%" }}
              >
                <div
                  class="accordion-item navbarAlignment"
                  id="accordionExampleProvider"
                  style={{ background: "transparent" }}
                >
                  <h2 class="accordion-header customAccordionHeader customAccordionHeader">
                    <button
                      id="accordionToggleButtonProvider"
                      className={`accordion-button collapsed buttonHeight ${
                        !isAccordionOpenProvider ? "openMargin" : "closedMargin"
                      }`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                      style={{ background: "transparent" }}
                      onClick={handleAccordionToggleProvider}
                    >
                      <Nav.Link
                      // as={NavLink}
                      // to={{
                      //   pathname: "/appointment",
                      //   state: { name: "provider" },
                      // }}
                      // className="linee2"
                      >
                        <p
                          onClick={() => handleNavigate("provider")}
                          className="providerText"
                        >
                          Providers
                        </p>
                      </Nav.Link>
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse "
                    data-bs-parent="#accordionExample"
                    style={{ width: "max-content", marginLeft: "5%" }}
                  >
                    <div class="accordion-body custom-accordion-body">
                      <Nav.Link
                        // as={NavLink}
                        // to="/submit-a-referral"
                        as={NavLink}
                        to={{
                          pathname: "/submit-a-referral",
                          state: { name: "submit-a-referral" },
                        }}
                        className="linee2 nav-link Submitrefferal"
                        // className="linee2"
                        style={{
                          // backgroundColor: "#fff",
                          borderRadius: "14px",
                          width: "max-content",
                        }}
                      >
                        <p
                        // style={{ borderBottom: "2px solid #D3D3D3" }}
                        >
                          Submit a Referral
                        </p>
                      </Nav.Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Nav.Link
                as={NavLink}
                to={{
                  pathname: "/appointment",
                  state: { name: "provider" },
                }}
                className="linee2"
              >
                {" "}
                Providers
                <KeyboardArrowDownIcon
                  onClick={() => setDropdown((prev) => !prev)}
                />
                {dropdown && (
                  <NavbarDropdown
                    submenus={[
                      { title: "Submit a Referral", url: "/submit-a-referral" },
                    ]}
                  />
                )}
              </Nav.Link> */}

              <Nav.Link
                as={NavLink}
                to="/treatment"
                className="linee2"
                style={{ marginTop: "3%" }}
              >
                {" "}
                Treatment
              </Nav.Link>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
