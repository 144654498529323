import React, { useEffect, useState } from 'react'
import '../Servicess/services.css'
import AOS from 'aos'
import "aos/dist/aos.css"
import { useLocation, useNavigate } from 'react-router-dom';

AOS.init();
const Services3 = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768 ? true : false)
  const updateDimensions = () => {
    setIsMobile(window.innerWidth < 768 ? true : false);

  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isMobile]);
  let navigate = useNavigate()
  const location = useLocation();
  const navigate_ = (url) => {
    navigate(url)
  }
  const url = location.pathname

  return (
    <>
      <div className={` ${url === "/home" ? "services3_main" : isMobile ? "services3_mobile" : "services3_ambulatory"}`}>
        <div className='services_data' data-aos="fade-right"
          data-aos-offset="300" data-aos-duration="1000"
          data-aos-easing="ease-in-sine">

          <p className={` ${url === "/home" ? "services_heading" : "services_heading-ambulatory"}`} onClick={() => navigate_('/services/specialty-drugs')}>Specialty Drugs</p>
          <p className={` ${url === "/home" ? "services_para" : "services_para-ambulatory"}`}  >From specialty drugs to antibiotics, Executive infusion provides excess to every medication and constantly expands the list to treats the patients.</p>
          <button onClick={() => navigate_('/services/specialty-drugs')} className={` ${url === "/home" ? "services_btn" : "services-btnAmbulatory"}`} >{url === "/home" ? "Book Our Specialist" : "Book An Appointment"}</button>

        </div>
      </div>
    </>
  )
}

export default Services3