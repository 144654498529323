import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import GreyTickSvg from "../assets/svgs/GreyTickSvg";
import "./FlexDiv.css";
const FlexDiv = ({
  flexTitle1,
  flexTitle2,
  flexTitle3,
  flexsubTitle,
  flexSvg,
  flextext1,
  flextext2,
  flextext3,
  flextext4,
  flextext5,
  flexRightImage,
  flexWebBgImgClass,
  flexMobileBg,
  file,
  services_form_text,
  services_title1,
}) => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 ? true : false
  );
  const updateDimensions = () => {
    setIsMobile(window.innerWidth < 768 ? true : false);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isMobile]);

  const location = useLocation();
  const url = location.pathname;
  return (
    <div
      className={`flexWrapper ${isMobile ? flexMobileBg : flexWebBgImgClass}`}
    >
      <div className="container d-flex mobileStyling">
        <div className=" flex-l-side">
          <div className="left-flex">
            <p className="left-flex-title">
              {flexTitle1}{" "}
              {url === "/spcldrug" ? isMobile ? "" : <br /> : <br />}
              {flexTitle2} <br /> {flexTitle3}
            </p>
            <p className="left-flex-subtitle">{flexsubTitle}</p>
            <div className="ul-div">
              <div className="li-flex-div">
                <div className="smallGreydivStyle">
                  <GreyTickSvg />
                </div>
                <p className="li-text">{flextext1}</p>
              </div>
              <div className="li-flex-div">
                <div className="smallGreydivStyle">
                  <GreyTickSvg />
                </div>
                <p className="li-text">{flextext2}</p>
              </div>
              {/* <div className="li-flex-div">
                <div className="smallGreydivStyle">
                  <GreyTickSvg />
                </div>
                <p className="li-text">{flextext3}</p>
              </div> */}
              {flextext4 && (
                <div className="li-flex-div">
                  <div className="smallGreydivStyle">
                    <GreyTickSvg />
                  </div>
                  <p className="li-text">{flextext4}</p>
                </div>
              )}

              {flextext4 && (
                <div className="li-flex-div">
                  <div className="smallGreydivStyle">
                    <GreyTickSvg />
                  </div>
                  <p className="li-text">{flextext5}</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex-r-side">
          <img
            src={flexRightImage}
            className="flexRightImageStyle"
            alt="flexImage"
          />
        </div>
      </div>
      <div className="container mt-3">
        <a
          href={file}
          download={`${services_title1}_Treatment_form`}
          target="_blank"
        >
          <button
            className="servicesBtnClass"
            style={{
              width: "auto",
            }}
            // onClick={() => handleOpen()}
          >
            {services_form_text}
          </button>
        </a>
      </div>
    </div>
  );
};

export default FlexDiv;
