import React from 'react'
import Footer from '../Componenets/Footer/Footer'
import Header from '../Componenets/Navbar/Navbar'
import Faaq from '../Componenets/Faq/Faaq'
import Contact from '../Componenets/Contact/Contact'
import {useEffect} from 'react';

const Faq = () => {
    
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
    
    return (
        <>
            <Header />
            <Faaq />
            <Contact />
            <Footer />
        </>
    )
}

export default Faq
