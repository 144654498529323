import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Wellness from "./Pages/Wellness";
import Appointment from "./Pages/Appointment";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Term from "./Pages/Term";
import Raferal from "./Pages/Raferal";
import Faq from "./Pages/Faq";
import PayerPage from "./Pages/PayerPage";
import PayePage from "./Pages/PayePage";
import SubmitReferal from "./Pages/SubmitReferal";

import NavigationPage from "./Pages/NavigationPage";

import ServicesLayout from "./Componenets/ServicesLayout/ServicesLayout";
// import spDrugDivImgae from "../src/Componenets/assets/images/spDrugDivImgae.png";
// import { AmbulatoryData } from "../src/data/AmbulatoryData";
// import { compoundingData } from "../src/data/compoundingData";
// import { HomeInfusionData } from "../src/data/HomeInfusionData";
// import { SpecialDrugData } from "../src/data/SpecialDrugData";
// import ambulatoryFlexImage from "../src/Componenets/assets/images/ambulatoryFlexImage.png";
// import spDrugFlexImage from "../src/Componenets/assets/images/spDrugFlexImage.png";
// import compoundingDivImage from "../src/Componenets/assets/images/compoundingDivImae.png";
// import compoundingFlexImage from "../src/Componenets/assets/images/compoundingFlexImage.png";

// import HomeInfFlexImage from "../src/Componenets/assets/images/homeInfflexDiv.png";
// import HomeInfDivImage from "../src/Componenets/assets/images/HomeInfDivImage.png";
// import AmbulatorydivImage from "../src/Componenets/assets/images/AmbulatorydivImage.png";
import { servicesLayoutData } from "./data/servicesLayoutData";
import OurServicesPage from "./Pages/OurServicesPage.jsx";
import WebBanner from "./Componenets/WebBanner/WebBanner";
import ServicesSublayout from "./Componenets/ServicesLayout/ServicesSublayout ";
import IVInfusions from "./Pages/IVInfusions";
import { useEffect, useState } from "react";
import { fetchReviews } from "./api";
import WhatsAppChat from "./Componenets/WhatsAppChat/WhatsAppChat";
import NotFound from "./Pages/NotFound";
import axios from "./axios";
import WellNessDetail from "./Componenets/WellNessGrid/WellNessDetail";

function App() {
  const [reviewData, setReviewData] = useState([]);
  useEffect(() => {
    // const fetchReviews = async () => {
    //   const apiKey = "AIzaSyAL9D1zdnszzf7FLAxnNr8vmyfJHUjdtrI";
    //   const placeId = "ChIJ0fnXAhw1O4gRLORDSGrbb8k";
    //   const corsProxy = "https://cors.eu.org/";
    //   const url = `${corsProxy}https://maps.googleapis.com/maps/api/place/details/json?placeid=${placeId}&key=${apiKey}`;

    //   try {
    //     const response = await axios.get(url);
    //     console.log(response)
    //     if (response?.data?.status === "OK") {
    //       console.log(response?.data, "response.data.result.reviews");
    //       setReviewData(response?.data?.result?.reviews);
    //     }
    //   } catch (error) {
    //     console.error("Error fetching reviews:", error);
    //   }
    // };

    // fetchReviews();
  }, []);

  return (
    <BrowserRouter>
      <WhatsAppChat />
      <Routes>
        <Route path="/" element={<Home reviewData={reviewData} />} />
        <Route path="home" element={<Home reviewData={reviewData} />} />
        <Route path="wellness" element={<Wellness reviewData={reviewData} />} />
        <Route path="wellnessdetail" element={<WellNessDetail />} />
        {/* <Route
          path="IVInfusions"
          element={<IVInfusions reviewData={reviewData} />}
        /> */}
        <Route path="appointment" element={<Appointment />} />
        <Route path="privacy" element={<PrivacyPolicy />} />
        <Route path="submit-a-referral" element={<SubmitReferal />} />
        <Route path="treatment" element={<SubmitReferal />} />
        <Route path="terms" element={<Term />} />
        <Route path="raferal" element={<Raferal />} />
        <Route path="/navigate" element={<NavigationPage />} />
        <Route path="faq" element={<Faq />} />
        <Route
          path="services/:subservice"
          element={
            <ServicesLayout
              Layout={ServicesSublayout}
              data={servicesLayoutData}
              reviewData={reviewData}
            />
          }
        />
        <Route path="payer" element={<PayerPage reviewData={reviewData} />} />
        <Route path="paybill" element={<PayePage reviewData={reviewData} />} />
        <Route
          path="our-services"
          element={<OurServicesPage reviewData={reviewData} />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
