import React, { useEffect } from "react";
import AppointmentBanner from "../Componenets/Appointment/AppointmentBanner";
import AppointmentForm from "../Componenets/AppointmentForm/AppointmentForm";
import Footer from "../Componenets/Footer/Footer";
import Header from "../Componenets/Navbar/Navbar";
import { useLocation } from "react-router-dom";

const Appointment = () => {
  let location = useLocation();
  let data = location.state?.data;
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <AppointmentBanner />
      <AppointmentForm patient={data} />
      <Footer />
    </>
  );
};

export default Appointment;
