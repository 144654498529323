import React, { useEffect, useRef } from "react";
import Contact from "../Componenets/Contact/Contact";
import Footer from "../Componenets/Footer/Footer";
import Header from "../Componenets/Navbar/Navbar";
import OurServices from "../Componenets/OurServices/OurServices";
import Services1 from "../Componenets/Servicess/Services1";
// import Testimonial from '../Componenets/Testimonial/Testimonial';

const OurServicesPage = ({ reviewData }) => {
  // useEffect(() => {
  //   // 👇️ scroll to top on page load
  //   window.scrollTo(100, 800);
  // }, []);
  return (
    <>
      <Header />
      <OurServices />
      {/* {reviewData.length > 0 && <Testimonial reviewData={reviewData} />} */}

      <Contact />
      <Footer />
    </>
  );
};

export default OurServicesPage;
