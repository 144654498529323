import spDrugDivImgae from "../../src/Componenets/assets/images/spDrugDivImgae.png";
import { AmbulatoryData } from "../../src/data/AmbulatoryData";
import { compoundingData } from "../../src/data/compoundingData";
import { HomeInfusionData } from "../../src/data/HomeInfusionData";
import { SpecialDrugData } from "../../src/data/SpecialDrugData";
import ambulatoryFlexImage from "../../src/Componenets/assets/images/ambulatoryFlexImage.png";
import spDrugFlexImage from "../../src/Componenets/assets/images/spDrugFlexImage.png";
import compoundingDivImage from "../../src/Componenets/assets/images/compoundingDivImae.png";
import compoundingFlexImage from "../../src/Componenets/assets/images/compoundingFlexImage.png";

import HomeInfFlexImage from "../../src/Componenets/assets/images/homeInfflexDiv.png";
import HomeInfDivImage from "../../src/Componenets/assets/images/HomeInfDivImage.png";
import AmbulatorydivImage from "../../src/Componenets/assets/images/AmbulatorydivImage.png";
import file1 from "../../src/Componenets/assets/files/Form 1 (Ambulatory Infusion Referral Form).pdf-.pdf";
import file2 from "../../src/Componenets/assets/files/Form 2 (Compounding Order Form).pdf-.pdf";
import file3 from "../../src/Componenets/assets/files/Form 3 (Home Infusion Referral Form).pdf-.pdf";
import file5 from "../../src/Componenets/assets/files/Form 5 (Specialty Drugs Order Form).pdf-.pdf";
// import file6 from "../../src/Componenets/assets/files/Form 6 (Wellness Infusions Order Form).pdf-.pdf"

export const servicesLayoutData = {
  ambulatory: {
    services_title1: "ambulatory",
    services_title2: "infusion center",
    services_subtitle:
      "Our clinical pharmacists and registered nurses manage ambulatory infusions to support the therapy needs of patients.",
    services_btn_text: "Book An Appointment",
    welltitle1: "Comprehensive Care",
    welltitle2: "Saving Lives",
    wellsubtitle:
      "Executive Infusion ambulatory center is convenient and safe for patients to receive infusion therapies. Every facility at the center and our dedicated healthcare professionals are focused on providing the highest quality of infusion care throughout every patient's treatment.",
    wellimage: AmbulatorydivImage,
    benifitsTitle: "Infusion Care That Makes A Difference",
    benifitsSubTitle:
      "We help patients take control of their lives by providing comprehensive care at our ambulatory infusion center.",
    array: AmbulatoryData,
    flexTitle1: "Re-Imagining Infusion",
    flexTitle2: "Therapies With",
    flexTitle3: "Confidence & Care",
    flexsubTitle:
      "As independent infusion therapy providers, we have the facilities and experience at our ambulatory infusion center to deliver.",
    flextext1: "Effective Wellness Infusions",
    flextext2: "Nursing & Clinician Support ",
    flextext3: "Extraordinary Care & Convenience",
    flexRightImage: ambulatoryFlexImage,
    servicesBgClass: "ambulatoryBgClass",
    servicesBtnClass: "servicesBtnClass",
    services_headingClass: "services_headingClass",
    ambulatoryMobileBgClass: "ambulatoryMobileBgClass",
    flexWebBgImgClass: "ambulatoryFlexWebBgClass",
    flexMobileBg: "ambulatoryFlexMobileBgClass",
    file: file1,
    services_form_text: "Download Ambulatory Infusion Referral Form",
  },
  "specialty-drugs": {
    services_title1: "specialty drugs",
    services_title2: "",
    services_subtitle:
      "From specialty drugs to antibiotics, Executive Infusion provides access to every medication and constantly expands the list to treat patients.",
    services_btn_text: "Book An Appointment",
    welltitle1: "Supporting Journeys",
    welltitle2: "To Recovery",
    wellsubtitle:
      "Executive Infusion has partnered with biotech and pharmaceutical companies to bring specialty drugs to the market for healthcare providers and patients. We believe in providing tailored healthcare solutions and specialty drugs to meet their infusion care needs.",
    wellimage: spDrugDivImgae,
    benifitsTitle: "Improved Patient Care & Recovery",
    benifitsSubTitle:
      "We serve the patients and pave their way to recovery by providing access to injectable and specialty infusible drugs.",
    array: SpecialDrugData,
    flexTitle1: "We Are Your Partner",
    flexTitle2: "In Care",
    flexTitle3: "",
    flexsubTitle:
      "Managing complex infusion therapies is complicated. Patients need more than just IVs, which is why we provide access to specialty drugs.",
    flextext1: "Little To No Wait Times",
    flextext2: "High-Quality Infusions ",
    flextext3: "Controlled Administration Environment",
    flexRightImage: spDrugFlexImage,
    servicesBgClass: "spdrugBgClass",
    servicesBtnClass: "servicesBtnClass",
    services_headingClass: "services_headingClass",
    ambulatoryMobileBgClass: "spdrugMobileBgClass",
    flexWebBgImgClass: "spcldrugFlexWebBgClass",
    flexMobileBg: "spcldrugFlexMobileBgClass",
    file: file5,
    services_form_text: "Download Specialty Drugs Order Form",
  },
  compounding: {
    services_title1: "Compounding",
    services_title2: "",
    services_subtitle: "Customized Meds for Personalized Healing.",
    services_btn_text: "BOOK A CONSULTATION",
    welltitle1: "Compounding More",
    welltitle2: "Than Just Medicines",
    wellsubtitle:
      "Executive Infusion compounds medications that are not available commercially. We also have arrangements with compounding pharmacies for sourcing compounding ingredients and equipment.",
    wellimage: compoundingDivImage,
    benifitsTitle: "Simplifying Treatments By Compounding",
    benifitsSubTitle:
      "To meet the needs of patients, our compounding professionals prepare compounded medications based on specific formulas and ingredients.",
    array: compoundingData,
    flexTitle1: "Taking Prescriptions",
    flexTitle2: "To The Next Level",
    flexTitle3: "",
    flexsubTitle:
      "Standard medications do not suit every patient. To provide for their specific needs, we compound medications.",
    flextext1: "Tailored Care",
    flextext2: "Advanced Equipment",
    flextext3: "FDA-Approved Compounds ",
    flexRightImage: compoundingFlexImage,
    servicesBgClass: "compoundingBgClass",
    servicesBtnClass: "servicesBtnClass",
    services_headingClass: "services_headingClass",
    ambulatoryMobileBgClass: "compoundingMobileBgClass",
    flexWebBgImgClass: "compoundingFlexWebBgClass",
    flexMobileBg: "compoundingFlexMobileBgClass",
    file: file2,
    services_form_text: "Download Compounding Order Form",
  },
  "home-infusion": {
    services_title1: "Home infusion",
    services_title2: "",
    services_subtitle: "IV Therapy at the comfort of your home",
    services_btn_text: "Book An Appointment",
    welltitle1: "Trusted At-Home",
    welltitle2: "Infusion Providers",
    wellsubtitle:
      "Our at-home infusion care service is an effective and safe inpatient care alternative. Annually, more than a hundred patients suffering from medical conditions that require therapies undergo treatment at their homes; under the care of Executive Infusion's clinicians and nurses. ",
    wellimage: HomeInfDivImage,
    benifitsTitle: "Care In The Comfort Of Your Home",
    benifitsSubTitle:
      "We are committed to assuring that every patient receives effective infusion therapy regardless of their location.",
    array: HomeInfusionData,
    flexTitle1: "Treating Patients",
    flexTitle2: "Wherever They Are",
    flexTitle3: "",
    flexsubTitle:
      "We help patients avoid extended stays at hospitals or infusion centers by administering infusions at home. Our approach at this service is to provide care in a comfortable environment.",
    flextext1: "Customized Infusion Care Plans",
    flextext2: "Convenient & Comfortable Experience",
    flextext3: "Controlled Therapy Administration",
    flexRightImage: HomeInfFlexImage,
    servicesBgClass: "homeInfBgClass",
    servicesBtnClass: "servicesBtnClass",
    services_headingClass: "services_headingClass",
    ambulatoryMobileBgClass: "HomeInfMobileBgClass",
    flexWebBgImgClass: "homeInfusionFlexWebBgClass",
    flexMobileBg: "homeInfusionFlexMobileBgClass",
    file: file3,
    services_form_text: "Download Home Infusion Referral Form ",
  },
};
