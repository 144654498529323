import React, { useEffect } from "react";
import "../SubmitReferal/SubmitRef.css";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { Accordion, Box, Grid } from "@mui/material";
import { AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionDetails } from "@mui/material";
import { yellow, grey } from "@mui/material/colors";
import SortedTable from "./Table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { CompressOutlined } from "@mui/icons-material";
import { Array } from "./FormData";
import pdf1 from "../../Componenets/assets/files/Form 1 (Ambulatory Infusion Referral Form).pdf-.pdf";
import pdf2 from "../../Componenets/assets/files/Form 1 (Ambulatory Infusion Referral Form).pdf-.pdf";
import pdf3 from "../../Componenets/assets/files/Form 1 (Ambulatory Infusion Referral Form).pdf-.pdf";
import { PDFDocument } from "pdf-lib";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation } from "react-router-dom";

const SubmitRef = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = typeof window !== "undefined" && window.innerWidth < 768;

  const [filteredList, setFilteredList] = React.useState(Array);

  const [render, setRender] = React.useState(false);

  const createData = (price) => {
    return { price };
  };
  const rows = filteredList.map((item) => createData(item));
  const [rowData, setRowData] = useState(rows);
  const [orderDirection, setOrderDirection] = useState("asc");
  const [treatment, setTreatment] = useState("");
  const [downloadText, setDownloadText] = useState("");
  const [searchText, setSearchText] = useState("");
  const [loadingRows, setLoadingRows] = useState([]);
  const [downloading, setDownloading] = useState(false);

  const sortArray = (arr, orderBy) => {
    switch (orderBy) {
      case "asc":
      default:
        return arr.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
      case "desc":
        return arr.sort((a, b) =>
          a.name < b.name ? 1 : b.name < a.name ? -1 : 0
        );
    }
  };

  const handleSortRequest = () => {
    setFilteredList(sortArray(filteredList, orderDirection));
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
  };

  const filterBySearch = (event) => {
    // Access input value
    const query = event.target.value;
    // Create copy of item list
    var updatedList = [...Array];
    // Include all elements which includes the search query
    updatedList = updatedList.filter((item) => {
      return item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    // Trigger render with updated values
    setFilteredList(updatedList);
    console.log(filteredList, "filteredList");
  };

  // const handleDownload = (row) => {
  //     console.log("id",row)

  // };
  // for downloading whole pdf

  // const handleDownload = (row) => {
  //   const pdf = filteredList.find((pdf) => pdf.id === row.id);
  //   console.log("pdf", pdf);
  //   if (pdf) {
  //     const link = document.createElement("a");
  //     link.href = pdf.file;
  //     link.download = pdf.name + ".pdf";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }
  // };

  //for downloading first page of pdf

  // const handleDownload = async (row) => {
  //   const pdf = filteredList.find((pdf) => pdf.id === row.id);
  //   if (pdf) {
  //     const response = await fetch(pdf.file);
  //     const pdfBytes = await response.arrayBuffer();

  //     // Load the PDF
  //     const pdfDoc = await PDFDocument.load(pdfBytes);

  //     // Create a new document with only the first page
  //     const firstPageDoc = await PDFDocument.create();
  //     const [copiedPage] = await firstPageDoc.copyPages(pdfDoc, [0]);
  //     firstPageDoc.addPage(copiedPage);

  //     // Serialize the modified PDF
  //     const modifiedPdfBytes = await firstPageDoc.save();

  //     // Create a Blob with the modified PDF
  //     const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });

  //     // Set the name of the downloaded PDF
  //     const filename = pdf.name + ".pdf";

  //     if (
  //       /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  //         navigator.userAgent
  //       )
  //     ) {
  //       // For mobile devices, open the PDF in a new tab
  //       debugger;
  //       const url = URL.createObjectURL(blob);

  //       window.open(url);
  //     } else {
  //       // For other devices, create a link and trigger the download
  //       const link = document.createElement("a");
  //       link.href = URL.createObjectURL(blob);
  //       link.download = filename;
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     }
  //   }
  // };

  // const handleDownload = async (row, index) => {
  //   setLoadingRows((prevLoadingRows) => [...prevLoadingRows, index]);
  //   const pdf = filteredList.find((pdf) => pdf.id === row.id);
  //   if (pdf) {
  //     const response = await fetch(pdf.file);
  //     const pdfBytes = await response.arrayBuffer();

  //     // Load the PDF
  //     const pdfDoc = await PDFDocument.load(pdfBytes);

  //     // Create a new document with only the first page
  //     const firstPageDoc = await PDFDocument.create();
  //     const [copiedPage] = await firstPageDoc.copyPages(pdfDoc, [0]);
  //     firstPageDoc.addPage(copiedPage);

  //     // Serialize the modified PDF
  //     const modifiedPdfBytes = await firstPageDoc.save();

  //     // Create a Blob with the modified PDF
  //     const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });

  //     // Set the name of the downloaded PDF
  //     const filename = pdf.name + ".pdf";

  //     // Determine if the user is on a mobile device
  //     const isMobile =
  //       /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  //         navigator.userAgent
  //       );

  //     if (isMobile) {
  //       // Open the PDF in a new tab on mobile devices
  //       const newTab = window.open("", "_blank");
  //       if (newTab) {
  //         // Write the PDF data to the new tab document
  //         const url = "https://www.executiveinfusion.com/submit-a-referral";
  //         newTab.location.href = url;

  //         // Set the download attribute to trigger the download
  //         const downloadLink = newTab.document.createElement("a");
  //         downloadLink.href = url;
  //         downloadLink.download = filename;
  //         downloadLink.style.display = "none";
  //         newTab.document.body.appendChild(downloadLink);
  //         downloadLink.click();
  //         newTab.document.body.removeChild(downloadLink);

  //         // Set the title of the new tab
  //         newTab.document.title = filename;
  //       }
  //     } else {
  //       // Download the PDF on PCs
  //       const url = URL.createObjectURL(blob);

  //       // Create a temporary anchor element
  //       const anchorElement = document.createElement("a");
  //       anchorElement.href = url;
  //       anchorElement.download = filename;
  //       anchorElement.style.display = "none";
  //       document.body.appendChild(anchorElement);
  //       anchorElement.click();
  //       document.body.removeChild(anchorElement);

  //       // Revoke the URL object to free up memory
  //       URL.revokeObjectURL(url);
  //     }
  //   }
  //   setLoadingRows((prevLoadingRows) =>
  //     prevLoadingRows.filter((i) => i !== index)
  //   );
  // };

  // name number
  // const handleDownload = async (row, index) => {
  //   // Set loading rows
  //   // setLoadingRows((prevLoadingRows) => [...prevLoadingRows, index]);

  //   const pdf = filteredList.find((pdf) => pdf.id === row.id);
  //   if (pdf) {
  //     const response = await fetch(pdf.file);
  //     const pdfBytes = await response.arrayBuffer();

  //     // Load the PDF
  //     const pdfDoc = await PDFDocument.load(pdfBytes);

  //     // Create a new document with only the first page
  //     const firstPageDoc = await PDFDocument.create();
  //     const [copiedPage] = await firstPageDoc.copyPages(pdfDoc, [0]);
  //     firstPageDoc.addPage(copiedPage);

  //     // Serialize the modified PDF
  //     const modifiedPdfBytes = await firstPageDoc.save();

  //     // Create a Blob with the modified PDF
  //     const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });

  //     // Set the name of the downloaded PDF
  //     const filename = pdf.name + ".pdf";

  //     // Create a temporary anchor element
  //     const link = document.createElement("a");
  //     link.href = URL.createObjectURL(blob);
  //     link.download = filename;

  //     // Check if the window.open function is being called
  //     if (!window.open) {
  //       console.log("The window.open function is not being called.");
  //     }

  //     if (
  //       /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  //         navigator.userAgent
  //       )
  //     ) {
  //       // For mobile devices, open the PDF in a new tab
  //       const url = link.href;
  //       const newTab = window.open(url);

  //       // Set the title of the new tab after the HTML document has finished loading
  //       setTimeout(() => {
  //         newTab.document.title = filename;
  //         newTab.document.name = filename;
  //       }, 1000);
  //     } else {
  //       // For other devices, trigger the download
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     }
  //   }
  //   // setLoadingRows((prevLoadingRows) =>
  //   //   prevLoadingRows.filter((i) => i !== index)
  //   // );
  // };

  // const handleDownload = async (row, index) => {
  //   // setLoadingRows((prevLoadingRows) => [...prevLoadingRows, index]);

  //   const pdf = filteredList.find((pdf) => pdf.id === row.id);
  //   if (pdf) {
  //     const response = await fetch(pdf.file);
  //     const pdfBytes = await response.arrayBuffer();

  //     // Load the PDF
  //     const pdfDoc = await PDFDocument.load(pdfBytes);

  //     // Create a new document with only the first page
  //     const firstPageDoc = await PDFDocument.create();
  //     const [copiedPage] = await firstPageDoc.copyPages(pdfDoc, [0]);
  //     firstPageDoc.addPage(copiedPage);

  //     // Serialize the modified PDF
  //     const modifiedPdfBytes = await firstPageDoc.save();

  //     // Create a Blob with the modified PDF
  //     const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });

  //     // Set the name of the downloaded PDF
  //     const filename = pdf.name + ".pdf";

  //     // Create a temporary anchor element
  //     const link = document.createElement("a");
  //     link.href = URL.createObjectURL(blob);
  //     link.download = filename;

  //     if (
  //       /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  //         navigator.userAgent
  //       )
  //     ) {
  //       // For mobile devices, open the PDF in a new tab
  //       const url = link.href;
  //       const newTab = window.open(url);
  //       newTab.addEventListener("load", () => {
  //         newTab.document.title = filename;
  //       });
  //     } else {
  //       // For other devices, trigger the download
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     }
  //   }
  //   // setLoadingRows((prevLoadingRows) =>
  //   //   prevLoadingRows.filter((i) => i !== index)
  //   // );
  // };
  // alert
  // const handleDownload = async (row, index) => {
  //   const pdf = filteredList.find((pdf) => pdf.id === row.id);
  //   if (pdf) {
  //     const response = await fetch(pdf.file);
  //     const pdfBytes = await response.arrayBuffer();

  //     // Load the PDF
  //     const pdfDoc = await PDFDocument.load(pdfBytes);

  //     // Create a new document with only the first page
  //     const firstPageDoc = await PDFDocument.create();
  //     const [copiedPage] = await firstPageDoc.copyPages(pdfDoc, [0]);
  //     firstPageDoc.addPage(copiedPage);

  //     // Serialize the modified PDF
  //     const modifiedPdfBytes = await firstPageDoc.save();

  //     // Create a Blob with the modified PDF
  //     const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });

  //     // Set the name of the downloaded PDF
  //     const filename = pdf.name + ".pdf";

  //     // Create a temporary anchor element
  //     const link = document.createElement("a");
  //     link.href = URL.createObjectURL(blob);
  //     link.download = filename;

  //     if (
  //       /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  //         navigator.userAgent
  //       )
  //     ) {
  //       // For mobile devices, show a notification with the expected filename
  //       alert(`The download has started. Expected filename: ${filename}`);

  //       // Trigger the download
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     } else {
  //       // For other devices, trigger the download
  //       document.body.appendChild(link);
  //       link.click();
  //       // Wait for the download to complete before performing a hard reload
  //       await new Promise((resolve) => {
  //         link.addEventListener("load", resolve);
  //       });
  //       // Perform a hard reload of the page
  //       location.reload(true);
  //       document.body.removeChild(link);
  //     }
  //   }
  // };

  // single download

  const handleDownload = async (row, index) => {
    setDownloading(true);
    // ...
    const pdf = filteredList.find((pdf) => pdf.id === row.id);
    if (pdf) {
      const response = await fetch(pdf.file);
      const pdfBytes = await response.arrayBuffer();

      // Load the PDF
      const pdfDoc = await PDFDocument.load(pdfBytes);

      // Create a new document with only the first page
      const firstPageDoc = await PDFDocument.create();
      const [copiedPage] = await firstPageDoc.copyPages(pdfDoc, [0]);
      firstPageDoc.addPage(copiedPage);

      // Serialize the modified PDF
      const modifiedPdfBytes = await firstPageDoc.save();

      // Create a Blob with the modified PDF
      const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });

      // Set the name of the downloaded PDF
      const filename = pdf.name + ".pdf";

      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      // Trigger the download in the same tab for all devices
      document.body.appendChild(link);
      link.click();
      // setRender(!render);
      document.body.removeChild(link);
    }
    setDownloading(false);
    // ...
  };

  // React.useEffect(() => {
  //   setRender(!render);

  //   // window.location.reload;
  //   console.log(filteredList, "qwerty");
  // }, []);

  // console.log(filteredList);
  // const handleDownload = async (row) => {
  //   const pdf = filteredList.find((pdf) => pdf.id === row.id);
  //   if (pdf) {
  //     const response = await fetch(pdf.file);
  //     const pdfBytes = await response.arrayBuffer();

  //     // Load the PDF
  //     const pdfDoc = await PDFDocument.load(pdfBytes);

  //     // Create a new document with only the first page
  //     const firstPageDoc = await PDFDocument.create();
  //     const [copiedPage] = await firstPageDoc.copyPages(pdfDoc, [0]);
  //     firstPageDoc.addPage(copiedPage);

  //     // Serialize the modified PDF
  //     const modifiedPdfBytes = await firstPageDoc.save();

  //     // Create a Blob and download the modified PDF
  //     const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
  //     const link = document.createElement("a");
  //     link.href = URL.createObjectURL(blob);
  //     link.download = pdf.name + ".pdf";
  //     document.body.appendChild(link);
  //     link.click();
  //     setRender(!render);
  //     document.body.removeChild(link);
  //     window.location.reload();
  //   }
  // };

  // const link = document.createElement("a");
  // link.href = pdf.file;
  // link.download = pdf.name + ".pdf";
  // document.body.appendChild(link);
  // link.click();
  // document.body.removeChild(link);

  const path = location.pathname;
  useEffect(() => {
    if (path === "/treatment") {
      setTreatment("Treatments Available");
    } else {
      setTreatment("Submit a Referral");
    }

    if (path === "/treatment") {
      setDownloadText(
        "Book your appointment for specific treatment with the fillable PDF forms. To download the treatment forms, click on their name."
      );
    } else {
      setDownloadText(
        "Submit a Referral Conveniently fillable referral form PDFs for specific medications or conditions are given below. To download the referral forms, click on their name."
      );
    }

    if (path === "/treatment") {
      setSearchText(
        "Click on the name of the treatment to download the PDF fillable forms. You can search our list of treatment forms in the search bar:"
      );
    } else {
      setSearchText(
        "Click on the name of the drug below to download the PDF referral form. You can search <br /> our list of drug referral forms in the search bar."
      );
    }
  }, [path]);

  return (
    <>
      <div className="raferal_main">
        <p className="raferal_heading">{treatment}</p>
      </div>
      <div style={{ paddingTop: "0px" }} className="faaq_main">
        <div className="fad_data">
          <div className="flex-center">
            <div className="para-head">
              <div className="flex">
                <strong>Email: </strong>
                <p style={{ color: "#6A7C8A" }}>
                  <a href="mailto:info@executiveinfusion.com">
                    &nbsp;info@executiveinfusion.com
                  </a>
                </p>
              </div>
              <div className="flex">
                <strong>Fax:</strong>
                <p style={{ color: "#6A7C8A" }}>
                  {" "}
                  &nbsp;+1 (313)-982-3221
                </p>{" "}
              </div>
            </div>
            <div className="para">
              {/* Submit a Referral Conveniently fillable referral form PDFs for
              specific medications or conditions are given below. To download
              the referral forms, click on their name. */}
              {downloadText}
            </div>
          </div>
          <div className="para-center">
            {/* Click on the name of the drug below to download the PDF referral
            form. You can search <br /> our list of drug referral forms in the
            search bar. */}
            {downloadText}
          </div>
          <div className="content-center">
            <strong>Search :</strong>
            <TextField
              sx={{ marginLeft: "20px" }}
              id="standard-basic"
              variant="standard"
              onChange={filterBySearch}
            />
          </div>
          <div className="content-center">
            <Grid container>
              {filteredList.map((row, index) => (
                <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                  <ul style={{ listStyle: "inside" }}>
                    <li
                      style={{
                        // width: "max-content",
                        color: "#000",
                        cursor: "pointer",
                      }}
                      // onClick={() => handleDownload(row, index)}
                      onClick={() => !downloading && handleDownload(row, index)}
                    >
                      <span
                        style={{ color: "#54A4DB", userSelect: "none" }}
                        className="pdfName"
                      >
                        {row.name}
                      </span>
                    </li>
                  </ul>
                </Grid>
              ))}
            </Grid>
            {/* <TableContainer
              sx={{ width: isMobile ? "100%" : "40%" }}
              component={Paper}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ background: "#25ABDF", textAlign: "start" }}>
                    <TableCell align="left" onClick={handleSortRequest}>
                      <TableSortLabel
                        style={{
                          color: "white",
                          fontWeight: "700",
                          fontSize: "18px",
                        }}
                        sx={{ textAlign: "start" }}
                        active={true}
                        direction={orderDirection}
                      >
                        Drug Name
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredList.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        className="select-text"
                        align="left"
                        onClick={() => handleDownload(row, index)}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>{row.name}</p>
                        {loadingRows.includes(index) && (
                          <Box>
                            <CircularProgress size={30} />
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubmitRef;
