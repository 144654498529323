import React from 'react'

const BlackUser = ({ stroke }) => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.4584 14.875V13.4583C13.4584 12.7069 13.1598 11.9862 12.6285 11.4549C12.0971 10.9235 11.3765 10.625 10.625 10.625H6.37502C5.62357 10.625 4.9029 10.9235 4.37155 11.4549C3.8402 11.9862 3.54169 12.7069 3.54169 13.4583V14.875" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.50002 7.79167C10.0648 7.79167 11.3334 6.52314 11.3334 4.95833C11.3334 3.39353 10.0648 2.125 8.50002 2.125C6.93521 2.125 5.66669 3.39353 5.66669 4.95833C5.66669 6.52314 6.93521 7.79167 8.50002 7.79167Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default BlackUser
