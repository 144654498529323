export const HomeInfusionData = [
    {
        title: "Convenient Infusion Therapy",
        subtitle: "Patients do not have to visit an infusion center to get infusion care. They can opt for convenient home-based infusion."
    },
    {
        title: "Access Specialists & Treatments ",
        subtitle: "Our committed nurses and clinicians provide every patient who is recommended IV therapy with the best at-home infusion treatment. "
    },
    {
        title: "More Comfortable Environment",
        subtitle: "At-home infusion therapy allows patients to access their treatment in an environment where they feel most relaxed and comfortable."
    }
]