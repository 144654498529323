import React from 'react'
import "./Benifits.css"
const Benifits = ({ benifitsTitle, benifitsSubTitle, array }) => {
    return (

        <div className='container marginStyling'>
            <div className="benifit-wrapper">
                <div className=''>
                    <p className='benifit-topText'>Benefits</p>
                    <p className='benifit-title'>{benifitsTitle}</p>
                    <p className='benifit-subtitle'>{benifitsSubTitle}</p>
                </div>
                <div className='wholeArrayWrapper mt-4'>
                    {
                        array?.map((value, i) => (
                            <div className='benifits-arraywrapper' key={i}>
                                <p className='benifits-arrayTitle'>{value.title}</p>
                                <p className='benifits-arraySubtitle'> {value.subtitle}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Benifits
