import drip1 from "../Componenets/assets/images/fluiddrip.webp";
import drip2 from "../Componenets/assets/images/hangoverdrip.webp";
import drip3 from "../Componenets/assets/images/drip3multivita.webp";
import drip4 from "../Componenets/assets/images/weightdrip.webp";
import drip5 from "../Componenets/assets/images/beautydrip.webp";
import drip6 from "../Componenets/assets/images/immunitydrip.webp";
import drip7 from "../Componenets/assets/images/drip7.performance.webp";
import drip8 from "../Componenets/assets/images/drip-8.cocktail.webp";
import drip9 from "../Componenets/assets/images/drip9.booster.webp";
export const drips = [
  {
    id: 1,
    image: drip1,
    title: "Fluid Hydration",
    subtitle: "Restore & Replenish",
    desc: "This intravenous solution is formulated to instantly hydrate your cells, facilitating rapid replenishment of electrolytes, restoration of fluid balance, and effective cleansing of your system.",
    b1: "Cellular hydration",
    b2: "Replenishing electrolyte levels",
    b3: "Detoxify and cleanse your body",
    b4: "Re-establishing fluid equilibrium",
    i1: "2 litres of Normal Saline Solution or IV lactated Ringer's fluid",
  },
  {
    id: 2,
    image: drip2,
    title: "Hangover Hydration",
    subtitle: "Recover & Relieve",
    desc: "This IV infusion alleviates hangover symptoms, enhances your immune system, boosts energy and concentration, and eliminates toxins from your body. The ultimate solution for hangover relief.",
    b1: "Helps hangover symptoms",
    b2: "Helps relieve headaches",
    b3: "Mitigate nausea",
    b4: "Soothe body soreness",
    b5: "Boost energy",
    b6: "Enhance mental focus",
    i1: "Ascorbic Acid (Vitamin C)",
    i2: "B1 Thiamine",
    i3: "B2 Riboflavin",
    i4: "B3 Niacin",
    i5: "B5 Pantothenic Acid",
    i6: "B6 Pyridoxine",
    i7: "MagnesiumCL",
    i8: "Zinc",
    i9: "Manganese",
    i10: "Copper",
    i11: "Selenium",
  },
  {
    id: 3,
    image: drip3,
    title: "Multi-Vitamin Formula",
    subtitle: "Support & Balance",
    desc: "Essential vitamins, and vital nutrients necessary for maintaining a healthy nutritional equilibrium.",
    b1: "Vitamin blend for Cellular health",
    b2: "Electrolyte Replenishment",
    b3: "Facilitate metabolism",
    b4: "Stabilize Insulin levels",
    b5: "Helps Immunity",
    i1: "Ascorbic acid (Vitamin C)",
    i2: "Vitamin A* (as palmitate)",
    i3: "Vitamin D3* (cholecalciferol)",
    i4: "Thiamine (Vitamin B1)",
    i5: "Riboflavin (Vitamin B2)",
    i6: "Pyridoxine HCl (Vitamin B6)",
    i7: "Niacinamide",
    i8: "Dexpanthenol",
    i9: "Vitamin E* (dl-α-tocopheryl acetate)",
    i10: "Vitamin K1* Folic acid",
    i11: "Biotin",
    i12: "Vitamin B12 (cyanocobalamin)",
  },
  {
    id: 4,
    image: drip4,
    title: "Weight Loss Booster",
    subtitle: "Energize & Burn Fat",
    desc: "Burn fat, improve energy levels, and enhance metabolic rate.",
    b1: "Boost energy levels",
    b2: "Burn fat",
    b3: "Increased Metabolism",
    i1: "B1 Thiamine",
    i2: "B2 Riboflavin",
    i3: "B3 Niacin",
    i4: "B5 Pantothenic Acid",
    i5: "B6 Pyridoxine",
    i6: "Glutamine",
    i7: "Arginine",
    i8: "Ornithine",
    i9: "Lysine",
    i10: "Citrulline",
    i11: "Carnitine",
  },
  {
    id: 5,
    image: drip5,
    title: "Beauty Blend",
    subtitle: "Nourish & Glow",
    desc: "Fight acne, wrinkles, and dead skin to Improve Overall Skin Health.",
    b1: "Fight acne",
    b2: "Prevent wrinkles",
    b3: "Improve Skin health",
    i1: "Ascorbic Acid (Vitamin C)",
    i2: "B1 Thiamine",
    i3: "B2 Riboflavin",
    i4: "B3 Niacin",
    i5: "B5 Pantothenic Acid",
    i6: "B6 Pyridoxine",
    i7: "Biotin",
  },
  {
    id: 6,
    image: drip6,
    title: "Immunity Blend",
    subtitle: "Prevent & Boost",
    desc: "Boost Immunity to help rapid recovery.",
    b1: "Boosts Immunity",
    b2: "Protection against pathogens",
    b3: "Increase Energy Levels",
    i1: "Ascorbic Acid (Vitamin C)",
    i2: "B1 Thiamine",
    i3: "B2 Riboflavin",
    i4: "B3 Niacin",
    i5: "B5 Pantothenic Acid",
    i6: "B6 Pyridoxine",
    i7: "Zinc Chloride",
  },
  {
    id: 7,
    image: drip7,
    title: "Performance Blend",
    subtitle: "Repair & Perform",
    desc: "Optimize your performance and reduce recovery time.",
    b1: "Facilitate muscle and tissue recovery",
    b2: "Reduce recovery time",
    b3: "Restore energy levels",
    b4: "Optimize overall performance",
    i1: "Ascorbic Acid (Vitamin C)",
    i2: "B1 Thiamine",
    i3: "B2 Riboflavin",
    i4: "B3 Niacin",
    i5: "B5 Pantothenic Acid",
    i6: "B6 Pyridoxine",
    i7: "Glutamine",
    i8: "Arginine",
    i9: "Ornithine",
    i10: "Lysine",
    i11: "Citrulline",
    i12: "Carnitine",
    i13: "MagnesiumCL",
    i14: "Zinc",
    i15: "Manganese",
    i16: "Copper",
    i17: "Selenium",
  },
  {
    id: 8,
    image: drip8,
    title: "Myers Cocktail",
    subtitle: "Protect & Defend",
    desc: "Strengthens the immune system, diminishes fatigue, alleviates seasonal allergies, and mitigates symptoms of fibromyalgia and asthma.",
    b1: "Fight seasonal allergies",
    b2: "Reduce fatigue",
    b3: "Boost immune system",
    b4: "Improve overall health and well-being",
    b5: "Alleviate fibromyalgia symptoms",
    b6: "Mitigate asthma symptoms",
    i1: "MagnesiumCL",
    i2: "B1 Thiamine",
    i3: "B2 Riboflavin",
    i4: "B3 Niacin",
    i5: "B5 Pantothenic Acid",
    i6: "B6 Pyridoxine",
    i7: "B12 Hydroxo",
    i8: "Calcium Gluconate",
    i9: "Ascorbic Acid (Vitamin C)",
  },
  {
    id: 9,
    image: drip9,
    title: "B12 Booster",
    subtitle: "Increase & Facilitate",
    desc: "Vitamin B12 stands as a vital nutrient with pivotal roles in numerous bodily functions. It aids in producing digestive enzymes, converting and synthesising various compounds like red blood cells and amino acids, as well as forming creatine. The supplementation of B12, commonly known as a B12 booster, can contribute to heightened energy, improved focus, and potential metabolism enhancement.",
    b1: "Elevate energy levels",
    b2: "Amplify metabolism",
    b3: "Enhance mental concentration",
    b4: "Facilitate protein's metabolic conversion",
    i1: "Concentrated Vitamin B12",
  },
];
