import React from "react";
import "../Home/home.css";
// import Header from '../Navbar/Navbar'
import { useNavigate } from "react-router-dom";
const HomeCom = () => {
  let navigate = useNavigate();
  const navigate_ = (url) => {
    navigate(url);
  };
  return (
    <>
      <div className="home_main">
        <div className="home_heading">
          <p className="home_title">
            <span className="highlightedTexts">Infusion Care</span>
            <br />
            <span className="highlightedTexts">Redefined</span>
          </p>
          <p className="home_para">journey to better health</p>
          <div className="home_btn_div">
            <button
              onClick={() => navigate_("/submit-a-referral")}
              className="home_btn"
            >
              Refer A Patient
            </button>
            <button
              onClick={() => navigate_("/our-services")}
              className="home_btn1"
            >
              Our Services{" "}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeCom;
