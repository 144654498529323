import React, { useEffect } from 'react'
import Contact from '../Componenets/Contact/Contact'
import Footer from '../Componenets/Footer/Footer'
import Header from '../Componenets/Navbar/Navbar'
import Privacy from '../Componenets/Privacy/Privacy'

const PrivacyPolicy = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <Header />
            <Privacy />
            <Contact/>
            <Footer />

        </>
    )
}

export default PrivacyPolicy
