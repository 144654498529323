import React from "react";
import Header from "../Componenets/Navbar/Navbar";
import Contact from "../Componenets/Contact/Contact";
import Footer from "../Componenets/Footer/Footer";
import Payer from "../Componenets/Payer/Payer";
import { useEffect } from "react";
// import Testimonial from '../Componenets/Testimonial/Testimonial';
const PayerPage = ({ reviewData }) => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Payer />
      {/* <Testimonial reviewData={reviewData} /> */}
      <Contact />
      <Footer />
    </>
  );
};

export default PayerPage;
