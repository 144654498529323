import React from 'react'
import '../Payer/payer.css'
import payer1 from '../assets/images/firstpayer.png'
import payer2 from '../assets/images/payertwo.png'
import payer3 from '../assets/images/payerthree.png'
import payer4 from '../assets/images/payerfour.png'
import payer5 from '../assets/images/payerfive.png'
import payer6 from '../assets/images/payersiex.png'
import payer7 from '.././assets/images/payerseven.png'
import payer8 from '../assets/images/payereeight.png'
import payer9 from '../assets/images/payernine.png'
import WellNessDivs from '../WellNessDivs/WellNessDivs'
import payersDivsImage from "../assets/images/payersDivsImage.png"

const Payer = () => {
    return (
        <>
            <div className='payer_main'>
                <div className='payer_heading'>
                    <p className='payer_title'>Payers / Insurance <br /> Providers </p>
                    <p className='payer_para'>As independent infusion therapy providers with extensive experience, we have partnered with payers to bring patients affordable healthcare plans.</p>
                    <div className='payer_btn_div'>

                    </div>
                </div>
            </div>


            <WellNessDivs welltitle1={"Facilitating Infusion"} welltitle2={"care in every Way"} wellsubtitle={"Our partnerships with payers result in reduced infusion care costs. We facilitate infusion therapies every step of the way - with insurance coverage verification and nursing support."} wellimage={payersDivsImage} />


            <div className='network_div'>
                <div className='network_data'>
                    <p className='network_heading'>Our Network Of Payers</p>
                    <p className='network_para'>By partnering with local payers/ insurance providers, we tailor safe and low-cost infusion care plans for every patient.</p>

                </div>
                <div className='payer_sponsor'>
                    <div className='sponsorDiv'>
                        <div className='sponosr_logo'>
                            <img src={payer1} alt="dripPic" />
                            <p className='p-textmargin'>Blue Cross Blue Shield</p>
                        </div>
                        <div className='sponosr_logo'>
                            <img src={payer2} alt="dripPic" />
                            <p className='p-textmargin'>Anthem</p>
                        </div>
                        <div className='sponosr_logo'>
                            <img src={payer3} alt="dripPic" />
                            <p className='p-textmargin'>Cigna</p>
                        </div>


                    </div>
                    <div className='sponsorDiv'>

                        <div className='sponosr_logo'>
                            <img src={payer4} alt="dripPic" />
                            <p className='p-textmargin'>United Healthcare</p>
                        </div>
                        <div className='sponosr_logo'>
                            <img src={payer5} alt="dripPic" />
                            <p className='p-textmargin'>Aetna</p>
                        </div>
                        <div className='sponosr_logo'>
                            <img src={payer6} alt="dripPic" />
                            <p className='p-textmargin'>humana</p>
                        </div>


                    </div>
                    <div className='sponsorDiv'>

                        <div className='sponosr_logo'>
                            <img src={payer7} alt="dripPic" />
                            <p className='p-textmargin'>CareCentrix</p>
                        </div>
                        <div className='sponosr_logo'>
                            <img src={payer8} alt="dripPic" />
                            <p className='p-textmargin'>Tricare</p>
                        </div>
                        <div className='sponosr_logo'>
                            <img src={payer9} alt="dripPic" />
                            <p className='p-textmargin'>Triwest</p>
                        </div>

                    </div>

                </div>

            </div>



        </>
    )
}

export default Payer