import React, { useEffect } from "react";
import Contact from "../Componenets/Contact/Contact";
import Footer from "../Componenets/Footer/Footer";
import Header from "../Componenets/Navbar/Navbar";
import Paybill from "../Componenets/PayBill/Paybill";
// import Testimonial from '../Componenets/Testimonial/Testimonial'

const PayePage = ({ reviewData }) => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Paybill />
      {/* {reviewData.length > 0 && <Testimonial reviewData={reviewData} />} */}
      <Contact />
      <Footer />
    </>
  );
};

export default PayePage;
