import React from 'react'

const AvtiveArrowSvg = () => {
    return (
        <>
            <svg width="16" height="16" viewBox="0 0 24 24"
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined svgStyleDropDown" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.75 13.125L10.5 7.875L5.25 13.125" stroke="#25ABDF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        </>
    )
}

export default AvtiveArrowSvg
