import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react'


const ValidationTextField = styled(TextField)({
  '& input:valid + fieldset': {
    borderColor: '#3A3A3A',
    borderWidth: 1,
    // width: "100%",
  },
  '& label': {
    color: '#3A3A3A',
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins",
  },
  '& input:valid:focus + fieldset': {
    padding: '4px !important',
    borderColor: "#25ABDF"
  },
});
const ValidateTextField = ({ label, value, onChange, name, type, onBlur, onKeyPress }) => {

  return (
    <>
      <ValidationTextField
        sx={{ marginTop: "15px ", marginBottom: "25px" }}
        label={label}
        variant="outlined"
        name={name}
        fullWidth
        value={value}
        onChange={onChange}
        type={type}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
      />
    </>
  )
}

export default ValidateTextField
