import React from "react";
import whatsAppLogo from "../../Componenets/assets/images/whatsapp.png";
const WhatsAppChat = () => {
  const handleClick = () => {
    const phoneNumber = "+92300000000"; // Replace with your phone number
    const message = "Hello! I am interested in your services.";

    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank");
  };
  return (
    <img
      src={whatsAppLogo}
      onClick={handleClick}
      style={{
        width: "70px",
        height: "70px",
        position: "fixed",
        zIndex: 999,
        bottom: "5%",
        right: "5%",
        cursor: "pointer",
      }}
    />
  );
};

export default WhatsAppChat;
