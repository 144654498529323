import React, { useEffect } from "react";
import HomeCom from "../Componenets/Home/HomeCom";
import Services1 from "../Componenets/Servicess/Services1";
import Services2 from "../Componenets/Servicess/Services2";
import Services3 from "../Componenets/Servicess/Services3";
import Services4 from "../Componenets/Servicess/Services4";
import Header from "../Componenets/Navbar/Navbar";
import Services5 from "../Componenets/Servicess/Services5";
import Services6 from "../Componenets/Servicess/Services6";
import Testimonial from "../Componenets/Testimonial/Testimonial";
import Contact from "../Componenets/Contact/Contact";
import Footer from "../Componenets/Footer/Footer";

const Home = ({ reviewData }) => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  console.log(reviewData, "review");
  return (
    <>
      <Header />
      <HomeCom />
      <Services1 />
      <Services2 />
      {/* <Services3 /> */}
      <Services4 />
      <Services5 />
      {/* <Services6 /> */}
      {reviewData?.length > 0 && <Testimonial reviewData={reviewData} />}
      <div id="google-reviews"></div>

      <Contact />
      <Footer />
    </>
  );
};

export default Home;
