import React from 'react'

const WhiteSimpleTick = () => {
    return (
        <>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.6666 3.5L5.24992 9.91667L2.33325 7" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        </>
    )
}

export default WhiteSimpleTick
